import { Box } from '@mui/system';
import LogoutButton from '../components/buttons/logoutButton';

const NotFoundPage = () => {
  return (
    <Box
      textAlign="center"
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      height={'100%'}
    >
      <h1>404 - Not Found</h1>
      <p>
        Sorry, the page you are looking for does not exist or you don't have permission to access
        it.
      </p>
      <p>Try signing in with a different account.</p>
      <br />
      <LogoutButton textColor="black" />
    </Box>
  );
};

export default NotFoundPage;
