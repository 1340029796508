import Bowser from 'bowser';

export const getDeviceInfo = () => {
  const parsedDeviceInfo = Bowser.getParser(window.navigator.userAgent).getResult();

  const deviceInfoComponents = [
    parsedDeviceInfo.browser.name,
    parsedDeviceInfo.browser.version,
    parsedDeviceInfo.os.name,
    parsedDeviceInfo.os.version,
    parsedDeviceInfo.platform.type
  ]

  return deviceInfoComponents.join("+")
}