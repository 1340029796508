import { Box, Card, CardHeader, useMediaQuery, useTheme } from '@mui/material';
import navBarSurePassLogo from '../../assets/navBarSurePassLogo.svg';
import { useState } from 'react';
import PublicRegistrationForm from './PublicRegistrationForm';
import Toast from '../../components/toast/Toast';
import PublicRegistrationConfirmationDialog, {
  DialogSentiment
} from './PublicRegistrationConfirmationDialog';

const PublicRegistration = () => {
  const theme = useTheme();
  const [generalError, setGeneralError] = useState('');
  const [dialogSentiment, setDialogSentiment] = useState<DialogSentiment>(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {generalError && (
        <Toast type="error" onClose={() => setGeneralError('')}>
          {generalError}
        </Toast>
      )}
      <PublicRegistrationConfirmationDialog
        sentiment={dialogSentiment}
        setSentiment={setDialogSentiment}
      />
      <Card
        sx={{
          boxShadow: 'none',
          width: isSmallScreen ? '98%' : '66%',
          borderRadius: theme.shape.borderRadius / 2,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '80vh',
          marginTop: '2%'
        }}
      >
        <CardHeader
          sx={{
            backgroundColor: theme.palette.primary.dark,
            padding: 4,
            // it's a lot of wasted space once the screen gets short...
            height: {
              sm: '10%',
              md: '15%',
              lg: '20%'
            }
          }}
          title={
            <Box
              component="img"
              src={navBarSurePassLogo}
              alt="SurePass logo"
              sx={{
                width: '160px',
                height: 'auto',
                margin: '0 24px'
              }}
            />
          }
        ></CardHeader>
        <PublicRegistrationForm setGeneralError={setGeneralError} openDialog={setDialogSentiment} />
      </Card>
    </>
  );
};

export default PublicRegistration;
