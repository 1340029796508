import { useMemo, useRef } from 'react';
import { useTheme } from '@mui/material';
import './confettiAnimation.css';

interface ConfettiStyle extends React.CSSProperties {
  '--confetti-color'?: string;
  '--fall-duration'?: string;
  '--rotation'?: string;
  '--translateY'?: string;
}

const DialogConfetti = ({ pieces }) => {
  const theme = useTheme();
  const confettiContainerRef = useRef(null);

  // Went with inline styles to match the flavor of MUI sx props and in an effort to limit devs having to flip between files.
  // However, we can easily pop these all into a nice CSS file to separate concerns
  const confettiPieces = useMemo(() => {
    const colors = [theme.palette.primary.main, theme.palette.brightTeal, theme.palette.skyBlue];
    return Array.from({ length: pieces }).map((_, index) => {
      // determines horizontal position from the left
      const fromLeft = Math.random() * 100 + '%';
      // between 2 and 7 seconds. this was arbitrary but I like it.
      const fallDuration = Math.random() * 5 + 2 + 's';
      const rotation = Math.random() * 360 + 'deg';
      const leftNum = parseFloat(fromLeft.replace('%', ''));
      const distanceFromCenter = Math.abs(leftNum - 50);
      const width = 5 + Math.random() * 10 + 'px';
      const height = 5 + Math.random() * 10 + 'px';
      const transform = `rotate(${rotation})`;
      const backgroundColor = colors[Math.floor(Math.random() * colors.length)];
      // the farther a confetti is from the center, the longer it falls.
      const translateY = `calc(100vh + ${distanceFromCenter * 3}vh)`;
      const animation = `fall ${fallDuration} linear infinite`;

      return (
        <div
          key={index}
          className="confetti-piece"
          style={
            {
              position: 'absolute',
              left: fromLeft,
              width,
              height,
              backgroundColor,
              transform,
              animation,
              '--rotation': rotation,
              '--translateY': translateY
            } as ConfettiStyle
          }
        />
      );
    });
  }, [pieces, theme.palette.brightTeal, theme.palette.primary.main, theme.palette.skyBlue]);

  return (
    <div
      data-testid="dialog-confetti"
      ref={confettiContainerRef}
      style={{
        position: 'absolute',
        top: '-10px',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: 1000,
        overflow: 'hidden',
        pointerEvents: 'none'
      }}
    >
      {confettiPieces}
    </div>
  );
};

export default DialogConfetti;
