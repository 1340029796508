import { Avatar } from '@mui/material';
import { MissingImageIcon } from '../../../assets/icons/icons';

const imageStyle = {
  width: '100%',
  height: '100%',
  borderRadius: 3
};

const missingStyle = {
  width: 117,
  height: 128
};

// TODO: Display picture when we have the correct format (jpg, png, etc)
export const UserAvatar = ({ picture }: { picture?: string }) => {
  if (picture) {
    return <Avatar alt="User Avatar" src={picture} sx={imageStyle} />;
  }
  return (
    <Avatar alt="User Avatar" sx={{ ...imageStyle, boxShadow: 2, backgroundColor: 'grey.300' }}>
      <MissingImageIcon sx={{ ...missingStyle, color: 'grey.400', backgroundColor: 'grey.300' }} />
    </Avatar>
  );
};

export default UserAvatar;
