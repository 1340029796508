import { Box, Avatar, Typography } from '@mui/material';

type OfficialAvatarProps = {
  img_url?: string;
  name?: string;
  square: boolean;
};

export default function OfficialAvatar({ img_url, name, square }: OfficialAvatarProps) {
  const smallSize = { width: 24, height: 24 };
  const bigSize = { width: 32, height: 32 };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, height: '100%' }}>
        <Avatar
          sx={square ? bigSize : smallSize}
          src={img_url}
          alt="avatar"
          variant={square ? 'rounded' : 'circular'}
        />
        {name && <Typography variant="body2">{name}</Typography>}
      </Box>
    </>
  );
}
