import React from 'react';
import AgencyOfficialsDataTable from '../features/agencyOfficials/components/agencyOfficialsDataTable';
import { Box, Container, Divider, Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

export const ManageOfficialsPage: React.FC = () => {
  const theme: Theme = useTheme();

  return (
    <Box component={'main'} gridArea={'main'}>
      <Container sx={{ height: '100%' }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2, mb: 2 }}
          color={theme.palette.text.primary}
        >
          <Typography variant="h4">Agency Officials</Typography>
          <Divider />
        </Box>
        <AgencyOfficialsDataTable />
      </Container>
    </Box>
  );
};

export default ManageOfficialsPage;
