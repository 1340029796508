import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid2 as Grid,
  Typography,
  useTheme
} from '@mui/material';
import surePassIcon from '../../../../..//assets/surePassIcon.svg';
import DialogConfetti from './DialogConfetti';

interface AddAgencyOfficialDialogCongratulationsProps {
  onClose: () => void;
  newOfficialName: string;
}

const AddAgencyOfficialDialogCongratulations = ({
  onClose,
  newOfficialName
}: AddAgencyOfficialDialogCongratulationsProps) => {
  const theme = useTheme();
  const padding = theme.spacing(3);

  return (
    <DialogContent sx={{ padding }}>
      <Grid
        container
        spacing={1}
        sx={{
          height: '60vh',
          backgroundColor: theme.palette.grey[50],
          borderRadius: theme.shape.borderRadius,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Box component="img" src={surePassIcon} alt="surepass logo" sx={{ width: '8%' }} />
        {/* variant h4 is too small, variant h3 is too big */}
        <Typography sx={{ fontSize: '2.5rem' }}>Congratulations!</Typography>
        <Grid size={4}>
          <Typography sx={{ textAlign: 'center' }}>
            {newOfficialName} has been added as an offiical
          </Typography>
        </Grid>
        <DialogConfetti pieces={50} />
      </Grid>
      <DialogActions sx={{ paddingTop: padding, paddingX: padding }}>
        <Button
          onClick={onClose}
          color="primary"
          variant="contained"
          disableElevation
          sx={{ borderRadius: 2 }}
        >
          Close
        </Button>
      </DialogActions>
    </DialogContent>
  );
};

export default AddAgencyOfficialDialogCongratulations;
