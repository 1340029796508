import styled from '@mui/system/styled';
import { ButtonProps } from '@mui/material';
import SmallButton from './SmallButton';

const StyledSmallButton = styled(SmallButton)({
  minWidth: 0,
  padding: '1px 3px'
});

export const SmallIconButton = (props: ButtonProps) => (
  <StyledSmallButton variant="outlined" size="small" color="secondary" {...props} />
);

export default SmallIconButton;
