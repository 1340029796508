import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Location } from '../../../networkRequest/locationsService';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Grid2 as Grid
} from '@mui/material';
import { LocationIcon } from '../../../assets/icons/icons';

interface LocationPickerProps {
  locations: Location[];
  onSelect: (selectedLocation: number) => Promise<void>;
}

export default function LocationPicker({ locations, onSelect }: LocationPickerProps) {
  const [selectedLocation, setSelectedLocation] = useState<string>(
    locations.length > 0 ? locations[0].id.toString() : ''
  );
  const theme = useTheme();
  const onChange = (event: SelectChangeEvent): void => {
    const locationId = event.target.value;
    setSelectedLocation(locationId);
    onSelect(Number(locationId));
  };

  useEffect(() => {
    if (locations.length > 0 && selectedLocation === '') {
      setSelectedLocation(locations[0].id.toString());
      onSelect(locations[0].id);
    }
  }, [locations, onSelect, selectedLocation]);

  return (
    <Grid size={3} container>
      <Grid
        size={3}
        padding={1}
        gap={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: theme.palette.powderBlue,
          borderRadius: '8px',
          width: '100%'
        }}
      >
        <LocationIcon
          sx={{
            color: theme.palette.navyBlue,
            fontSize: 30,
            '&.MuiInputLabel-outlined': { color: theme.palette.primary.dark }
          }}
        />
        <FormControl
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: '100%'
          }}
        >
          <InputLabel
            id="location-selector"
            shrink={true}
            sx={{
              position: 'static',
              transform: 'none',
              fontSize: '8pt',
              '&.MuiInputLabel-outlined.Mui-focused': { color: theme.palette.primary.dark }
            }}
          >
            Location
          </InputLabel>
          <Select
            value={selectedLocation}
            labelId="location-selector"
            id="custom-select"
            onChange={onChange}
            sx={{
              border: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 'none' },
              '.MuiSelect-select': { padding: 0 },
              '.MuiSelect-icon': { top: '-25%' },
              width: '100%'
            }}
          >
            {locations.map((location) => (
              <MenuItem key={location.id} value={location.id}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
