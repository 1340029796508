import { useParams } from 'react-router-dom';
import { Box, Container, Divider, Typography } from '@mui/material';
import UserDetails from '../features/userDetails/components/UserDetails';
import { BackArrowIcon } from '../assets/icons/icons';

export const UserDetailsPage = () => {
  const { officialId } = useParams();
  if (!officialId) {
    return <Typography>Error: No official ID provided</Typography>;
  }
  const officialIdNumber = Number(officialId);
  if (isNaN(officialIdNumber)) {
    return <Typography>Error: Invalid official ID</Typography>;
  }
  return (
    <Box component={'main'} gridArea={'main'}>
      <Container sx={{ height: '100%' }}>
        <Box color="text.primary" display={'flex'} flexDirection={'column'} gap={1} my={2}>
          <Typography variant="h4">User Details</Typography>
          <Box mt={1}>
            <Divider />
          </Box>
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => window.history.back()}
          >
            <BackArrowIcon sx={{ width: '16px', height: '16px', mr: '4px' }} />
            <Typography fontSize="small" fontWeight={600}>
              Back to Agency Officials
            </Typography>
          </Box>
          <Box mb={1}>
            <Divider />
          </Box>
        </Box>
        <UserDetails officialId={officialIdNumber} />
      </Container>
    </Box>
  );
};

export default UserDetailsPage;
