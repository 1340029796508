import Alert from '@mui/material/Alert';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { createContext, useContext, useState } from 'react';
import scope from './scope.ts';

const AuthContext = createContext({
  loginNeedsRecording: false,
  setLoginNeedsRecording: (_b: boolean) => {}
});

const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();
  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const redirectUri = import.meta.env.VITE_AUTH0_CALLBACK_URL;
  const audience = import.meta.env.VITE_AUTH0_AUDIENCE;
  const [loginNeedsRecording, setLoginNeedsRecording] = useState(false);

  const onRedirectCallback = (appState) => {
    if (!appState.silentAttempt) {
      setLoginNeedsRecording(true);
    }
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return <Alert severity="error">Error: Missing required Auth parameters.</Alert>;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
        scope
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      <AuthContext.Provider value={{ loginNeedsRecording, setLoginNeedsRecording }}>
        {children}
      </AuthContext.Provider>
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within Auth0ProviderWithNavigate');
  }
  return context;
};
