import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Grid2 as Grid
} from '@mui/material';
import styled from '@mui/system/styled';
import { Delete as TrashIcon } from '@mui/icons-material';
import { Theme, useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Confirmation } from '../../../components/confirmation/Confirmation';
import { useUser } from '../../../hooks/useUser';
import { Checkpoint, deleteCheckpoint } from '../../../networkRequest/checkpointsService';
import Toast from '../../../components/toast/Toast';
import { CheckpointIcon, LocationIcon } from '../../../assets/icons/icons';
import { CheckpointData, getData } from '../utils/data';
import SmallButton from '../../../components/SmallButton';
import EditCheckpointDialog from './EditCheckpointDialog';
import SmallIconButton from '../../../components/SmallIconButton';

const sidebarWidth = 250;

const singleLineTextStyle = {
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

const LocationItem = styled(ToggleButton)(({ theme }) => ({
  display: 'flex',
  width: sidebarWidth - 16,
  borderRadius: 0,
  padding: 8,
  justifyContent: 'flex-start',
  border: 'none',
  textTransform: 'none', // Don't capitalize text
  // Selected location
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.default
  }
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    backgroundColor: theme.palette.grey[100],
    padding: '0 8px',
    margin: '0',
    '&.Mui-expanded > *': {
      backgroundColor: theme.palette.background.default,
      borderRadius: '4px'
    }
  },
  '&.MuiAccordion-root.Mui-expanded': {
    paddingBottom: '8px'
  },
  '& .MuiAccordionSummary-root': {
    padding: '0 4px'
  },
  '& .MuiAccordionSummary-content': {
    margin: '4px 0'
  }
}));

const Checkpoints = () => {
  const user = useUser();
  const theme: Theme = useTheme();
  const [data, setData] = useState<CheckpointData | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogCheckpoint, setDialogCheckpoint] = useState<number | null>(null);
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(null);

  const selectedLocation = useMemo(
    () => (selectedLocationId ? data?.locationsById[selectedLocationId] : data?.locations[0]),
    [data, selectedLocationId]
  );

  const refreshData = useCallback(async () => {
    if (user) {
      try {
        const data = await getData(user);
        setData(data);
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    }
  }, [user]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const handleLocationChange = (
    _event: React.MouseEvent<HTMLElement>,
    newLocationId: number | null
  ) => {
    setSelectedLocationId(newLocationId);
  };

  const onDialogSave = async (_cp: Checkpoint) => {
    setSuccessMessage('Checkpoint saved');
    setIsDialogOpen(false);
    refreshData();
  };

  const onError = (error: string) => {
    setErrorMessage(error);
    setIsDialogOpen(false);
  };

  const onEditClick = async (id: number) => {
    setDialogCheckpoint(id);
    setIsDialogOpen(true);
  };

  const onAddClick = async () => {
    setDialogCheckpoint(null);
    setIsDialogOpen(true);
  };

  const remove = async (id: number) => {
    if (user) {
      await deleteCheckpoint(user.accessToken, id);
      setSuccessMessage(`Location deleted`);
      await refreshData();
    } else {
      setErrorMessage('Failed to delete location, user not found');
    }
  };

  const onConfirmDelete = async () => {
    if (dialogCheckpoint) {
      await remove(dialogCheckpoint);
      setIsConfirmationOpen(false);
    }
  };

  const onRemoveClick = (id: number) => {
    setDialogCheckpoint(id);
    setIsConfirmationOpen(true);
  };

  const clearError = () => setErrorMessage('');
  const clearSuccess = () => setSuccessMessage('');

  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 2 }}
        color={theme.palette.text.primary}
      >
        <Typography variant="h4">Checkpoints</Typography>
        <Box display="flex" flexDirection={'row'} alignItems={'center'}>
          <SmallButton onClick={onAddClick}>Add New Checkpoint</SmallButton>
        </Box>
      </Box>
      <Box display="flex" gap={2}>
        {/* Sidebar */}
        <Box
          width={sidebarWidth}
          bgcolor={theme.palette.grey[100]}
          px={1}
          py={2}
          alignSelf="flex-start"
          borderRadius={2}
        >
          <Typography variant="h6" p={1} pt={0}>
            Locations
          </Typography>
          <ToggleButtonGroup
            orientation="vertical"
            value={selectedLocation?.id}
            exclusive
            onChange={handleLocationChange}
          >
            {data?.locations.map((location) => (
              <LocationItem
                key={location.id}
                onClick={() => setSelectedLocationId(location.id)}
                value={location.id}
              >
                <LocationIcon sx={{ mr: 1, color: theme.palette.text.secondary }} />
                <Typography sx={singleLineTextStyle}>{location.name}</Typography>
              </LocationItem>
            ))}
          </ToggleButtonGroup>
        </Box>

        {/* Main Content */}
        <Box flex="1" alignItems="center">
          <Divider />
          <Box display="flex" p={2} alignItems="center">
            <Avatar sx={{ mr: 2, backgroundColor: theme.palette.secondary.main }}>
              <LocationIcon />
            </Avatar>
            <Box lineHeight={1}>
              <Typography variant="h5">{selectedLocation?.name}</Typography>
              <Typography variant="caption" letterSpacing={0}>
                {selectedLocation?.street_address_1}
                {selectedLocation?.street_address_2 && ` ${selectedLocation.street_address_2},`}
                {selectedLocation?.city && ` ${selectedLocation.city},`}
                {selectedLocation?.state && ` ${selectedLocation.state}`}
                {selectedLocation?.postal && ` ${selectedLocation.postal}`}
              </Typography>
            </Box>
          </Box>
          {selectedLocation &&
            data?.checkpointsByLocationId[selectedLocation.id]?.map((c, i) => (
              <StyledAccordion key={c.id} defaultExpanded={i === 0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <CheckpointIcon sx={{ mr: 1, color: theme.palette.text.secondary }} />
                  <Typography fontWeight={600} color={theme.palette.text.secondary}>
                    {c.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" justifyContent="space-between" mt={1} mb={2}>
                    <Box mr={'8%'}>{c.description}</Box>
                    <Box display="flex" gap={1} p={'2px'}>
                      <Box>
                        <SmallButton onClick={() => onEditClick(c.id)} variant="outlined">
                          Edit checkpoint
                        </SmallButton>
                      </Box>
                      <Box>
                        <SmallIconButton onClick={() => onRemoveClick(c.id)}>
                          <TrashIcon />
                        </SmallIconButton>
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={{ mt: 1 }} />
                  <Typography variant="h6" my={1}>
                    Checkpoint Devices
                  </Typography>
                  {c.device_configurations?.map((device, i) => (
                    <>
                      <Grid container key={i} py={1}>
                        <Grid size={4}>
                          <Typography variant="caption" noWrap>
                            Device Name
                          </Typography>
                          <Typography variant="body2" fontWeight={600} noWrap>
                            {device.device_name}
                          </Typography>
                        </Grid>
                        <Grid size={8}>
                          <Typography variant="caption">Device ID</Typography>
                          <Typography variant="body2" sx={singleLineTextStyle}>
                            {device.device_uuid}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 1 }} />
                    </>
                  ))}
                </AccordionDetails>
              </StyledAccordion>
            ))}
        </Box>
      </Box>
      {errorMessage && (
        <Toast type="error" onClose={clearError}>
          {errorMessage}
        </Toast>
      )}
      {successMessage && (
        <Toast type="success" onClose={clearSuccess}>
          {successMessage}
        </Toast>
      )}
      {data && selectedLocation && (
        <EditCheckpointDialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onError={onError}
          onSubmit={onDialogSave}
          location={selectedLocation}
          checkpoint={dialogCheckpoint ? data.checkpointsById[dialogCheckpoint] : undefined}
        />
      )}
      <Confirmation
        open={isConfirmationOpen}
        message="Deleting this checkpoint will permamenty remove it and all associated data. Are you sure?"
        onConfirm={onConfirmDelete}
        onCancel={() => setIsConfirmationOpen(false)}
        confirmText="Delete"
        cancelText="Cancel"
      />
    </>
  );
};

export default Checkpoints;
