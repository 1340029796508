import { useEffect, useState, useCallback } from 'react';
import { useUser } from '../../../hooks/useUser';
import { Toast } from '../../../components/toast/Toast';
import { PageLoader } from '../../../components/PageLoader/PageLoader';
import { getData, UserDetailData } from '../utils/data';
import UserDetailView from './UserDetailsView';

interface UserDetailsProps {
  officialId: number;
}

const UserDetails = ({ officialId }: UserDetailsProps) => {
  const user = useUser();
  const [data, setData] = useState<UserDetailData | null>(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const refreshData = useCallback(async () => {
    if (user) {
      try {
        const data = await getData(user, officialId);
        setData(data);
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    }
  }, [officialId, user]);

  useEffect(() => {
    refreshData().finally(() => setLoading(false));
  }, [refreshData]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      {data && <UserDetailView data={data} setData={setData} refreshData={refreshData} />}
      {errorMessage && (
        <Toast type="error" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Toast>
      )}
    </>
  );
};

export default UserDetails;
