import { getReq } from './api';
import { OfficialData } from './officialsService';

export interface CheckpointAccess {
  id: number;
  checkpoint_id: number;
  armed: boolean;
  device_uuid: string;
  verifier: OfficialData;
  official_credential_id: string;
  action_type: string;
  performed_at: string;
  agency_official: OfficialData;
}

export const fetchCheckpointAccesses = async (
  accessToken: string,
  locationId: number
): Promise<{ data?: CheckpointAccess[]; error?: string }> => {
  return getReq<CheckpointAccess[]>(`/locations/${locationId}/checkpoint_accesses`, accessToken);
};
