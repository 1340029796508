import { Step, StepLabel, Stepper, useTheme } from '@mui/material';

const steps = ['Credentials', 'Upload Photo', 'Finish'];

interface AddAgencyofficialDialogStepsProps {
  activeStep: number;
}
const AddAgencyOfficialDialogSteps = ({ activeStep }: AddAgencyofficialDialogStepsProps) => {
  const theme = useTheme();

  return (
    <Stepper activeStep={activeStep} sx={{ px: theme.spacing(3) }}>
      {steps.map((label) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: {
          optional?: React.ReactNode;
        } = {};
        return (
          <Step
            key={label}
            {...stepProps}
            sx={{
              '& .Mui-disabled .MuiStepIcon-root': { fill: theme.palette.grey[200] }
            }}
          >
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default AddAgencyOfficialDialogSteps;
