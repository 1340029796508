export const formatData = (data) => {
  // this is a generic formatData to put any array of objects into a CSV-friendly format
  // create string headers
  const columns = Object.keys(data[0]);
  const header = columns.map(column => `"${column}"`).join(',');

  const rows = data.map((obj) => {
    const rowData = columns.map((column) => {
      let value = obj[column];
      // make sure everything is a string for the CSV, handle double quotes
      if(value === null || value === undefined) value = "";
      return `"${String(value).replaceAll('"', '""')}"`;
    }).join(',');
    return rowData;
    // line break character
  }).join('\r\n');

  return `${header}\r\n${rows}`;
}

export const download = (data, fileName) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  // vanilla in react is often a red flag, but after going through a ton of stack overflow answers / blogs / projects it seems this is the common flow if not utilizing a library.
  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const removeSpecifiedDataPoints = (data, keysToExclude) => {
  return data.map((accessLog) => {
    return Object.fromEntries(Object.entries(accessLog).filter(([key]) => !keysToExclude.includes(key)));
  });
}