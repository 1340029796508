import { createContext, useContext, useEffect, useState, ReactNode, Context } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { findOfficial } from '../networkRequest/officialsService';

export interface UserProfile {
  email: string;
  id: number;
  img_url: string;
  status?: string;
  agency: {
    id: number;
    name: string;
    logo_url?: string;
  };
  role: {
    id: number;
    name: string;
    description: string;
  };
}

export type UserState = {
  accessToken: string;
  profile: UserProfile;
  email: string;
};

const UserContext: Context<UserState | null> = createContext<UserState | null>(null);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { getAccessTokenSilently, user, isLoading } = useAuth0();
  const [state, setState] = useState<UserState | null>(null);
  const [hasFetchedProfile, setHasFetchedProfile] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (user && user.email) {
          const { data: userProfile, error } = await findOfficial(accessToken, user.email);
          if (error) {
            throw new Error(error);
          }

          if (userProfile && userProfile.length > 0) {
            const userObj = { email: user.email, profile: userProfile[0], accessToken };
            setState(userObj);
            setHasFetchedProfile(true);
          } else {
            throw new Error(`Official with email ${user.email} not found`);
          }
        }
      } catch (error: any) {
        console.error('Something went wrong', error.message);
      }
    };

    if (!isLoading && user && !hasFetchedProfile) {
      fetchProfile();
    }
  }, [getAccessTokenSilently, user, isLoading, hasFetchedProfile]);

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

export const MockUserProvider = ({
  value,
  children
}: {
  value: UserState;
  children: ReactNode;
}) => {
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
