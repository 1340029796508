import { alpha, Box, Container, Typography, useTheme } from '@mui/material';
import PublicRegistration from '../features/publicRegistration/PublicRegistration';
import { copy } from '../containers/pageLayout/definitions';

export const PublicRegistrationPage = () => {
  const theme = useTheme();
  const clearBlue = alpha(theme.palette.skyBlue || theme.palette.primary.main, 0.1);

  return (
    <Box component={'main'} gridArea={'main'} sx={{ backgroundColor: clearBlue }}>
      <Container
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <PublicRegistration />
        <Typography variant="caption" paddingY={1} sx={{ color: theme.palette.grey[600] }}>
          {copy.copyrightFooter}
        </Typography>
      </Container>
    </Box>
  );
};
