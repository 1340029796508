import { Box, Container, Divider, Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import AccessLogsDataTable from '../features/accessLogs/components/AccessLogsDataTable';

export default function AccessLogsPage() {
  const theme: Theme = useTheme();

  return (
    <Box component={'main'} gridArea={'main'}>
      <Container sx={{ height: '100%' }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2, mb: 2 }}
          color={theme.palette.text.primary}
        >
          <Typography variant="h4">Access Logs</Typography>
          <Divider sx={{ marginBottom: 2 }} />
        </Box>
        <AccessLogsDataTable />
      </Container>
    </Box>
  );
}
