import { getAgencyNames } from '../../../networkRequest/agenciesService';
import { uploadPublicOfficialImage } from '../../../networkRequest/cloudinaryService';
import { publicAddAgencyOfficial } from '../../../networkRequest/officialsService';
import { NewOfficialWithToken} from '../PublicRegistrationForm';

export const getAgencies = async (): Promise<string[]> => {
  const { data, error } = await getAgencyNames();

  if (error) {
    throw new Error(error);
  } else if (!data) {
    throw new Error('No data returned');
  }

  return data;
};

export const uploadPublicPhoto = async (imageFile: File, agencyName: string) => {
  const { data, error } = await uploadPublicOfficialImage(imageFile, agencyName);

  if (error) {
    throw new Error(error);
  } else if (!data) {
    throw new Error('Failed to upload photo.');
  }

  return data;
};

export const registerOfficial = async (newOfficial: NewOfficialWithToken): Promise<number> => {
  const { error, status } = await publicAddAgencyOfficial(newOfficial);

  if (error) {
    throw new Error(error);
  } if (!status) {
    throw new Error('Failed to register official.');
  }

  return status;
};
