import { useEffect, useState, useCallback } from 'react';
import CustomToolBar from '../../../components/CustomToolBar.tsx';
import { createLocationColumns } from './LocationColumns';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { deleteAgencyLocation, Location } from '../../../networkRequest/locationsService';
import EditLocationDialog from './EditLocationDialog';
import Toast from '../../../components/toast/Toast';
import { Confirmation } from '../../../components/confirmation/Confirmation';
import { useUser } from '../../../hooks/useUser';
import { getLocationData } from '../utils/data';

export const AgencyLocationsTable: React.FC = () => {
  const user = useUser();
  const [locationsById, setLocationsById] = useState<Record<number, Location>>({});
  const [locations, setLocations] = useState<Location[]>([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogLocation, setDialogLocation] = useState<number | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<number | null>(null);

  const getLocations = useCallback(async () => {
    if (!user) {
      return;
    }
    try {
      const data = await getLocationData(user);
      setLocations(data.locations);
      setLocationsById(data.locationsById);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      await getLocations();
      setLoading(false);
    };
    fetchData();
  }, [getLocations]);

  const onDialogSave = async (_loc: Location) => {
    setSuccessMessage('Location saved');
    setIsDialogOpen(false);
    getLocations();
  };

  const onError = (error: string) => {
    setErrorMessage(error);
    setIsDialogOpen(false);
  };

  const onEditClick = async (id: number) => {
    setDialogLocation(id);
    setIsDialogOpen(true);
  };

  const onAddClick = async () => {
    setDialogLocation(null);
    setIsDialogOpen(true);
  };

  const remove = async (id: number) => {
    if (user) {
      await deleteAgencyLocation(user.accessToken, id);
      setSuccessMessage(`Location deleted`);
      await getLocations();
    } else {
      setErrorMessage('Failed to delete location, user not found');
    }
  };

  const onConfirmDelete = async () => {
    if (selectedLocation) {
      await remove(selectedLocation);
      setIsConfirmationOpen(false);
    }
  };

  const onRemoveClick = (id: number) => {
    setSelectedLocation(id);
    setIsConfirmationOpen(true);
  };

  const clearError = () => setErrorMessage('');
  const clearSuccess = () => setSuccessMessage('');
  const columns = createLocationColumns(onEditClick, onRemoveClick);

  return (
    <>
      <Box sx={{ flexGrow: 1, height: '100%' }}>
        <DataGrid
          rows={locations}
          columns={columns}
          loading={loading}
          disableRowSelectionOnClick
          slots={{
            toolbar: () => <CustomToolBar onAddClick={onAddClick} buttonText="Add Location" />
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton'
            }
          }}
          autosizeOptions={{
            columns: ['name', 'street_address_1', 'street_address_2', 'city'],
            includeOutliers: true,
            includeHeaders: false,
            expand: true
          }}
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '600'
            }
          }}
        />
        {errorMessage && (
          <Toast type="error" onClose={clearError}>
            {errorMessage}
          </Toast>
        )}
        {successMessage && (
          <Toast type="success" onClose={clearSuccess}>
            {successMessage}
          </Toast>
        )}
      </Box>
      <EditLocationDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onError={onError}
        onSubmit={onDialogSave}
        location={dialogLocation ? locationsById[dialogLocation] : undefined}
      />
      <Confirmation
        open={isConfirmationOpen}
        message="Deleting this location will permamenty remove it and all associated data. Are you sure?"
        onConfirm={onConfirmDelete}
        onCancel={() => setIsConfirmationOpen(false)}
        confirmText="Delete"
        cancelText="Cancel"
      />
    </>
  );
};

export default AgencyLocationsTable;
