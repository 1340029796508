import { useEffect, useState } from 'react';

declare global {
  interface Window {
    grecaptcha: {
      enterprise: {
        ready(callback: () => void): void;
        execute(siteKey: string, optoins: { action: string }): Promise<string>;
      };
    };
  }
}

type ReCaptchaResult = { token: string };
type ReCaptchaError = { error: string };

const useReCaptcha = (): {
  loading: boolean;
  generateReCaptchaToken: (action: string) => Promise<ReCaptchaResult | ReCaptchaError>;
} => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.defer = true;
    script.onload = () => setLoading(false);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const generateReCaptchaToken = async (
    action: string
  ): Promise<ReCaptchaResult | ReCaptchaError> => {
    // the ready method doesn't return a promise, but if we want to utilize async/await then we have to force a promise
    // the alternative is to utilize '.thens' to handle the resolutions but the code becomes really nested and I find it confusing
    return new Promise((resolve, reject) => {
      window.grecaptcha.enterprise.ready(async () => {
        try {
          const token = await window.grecaptcha.enterprise.execute(
            import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY,
            { action }
          );
          resolve({ token });
        } catch (error: unknown) {
          reject({ error: (error as Error)?.message || 'reCAPTCHA execution failed' });
        }
      });
    });
  };

  return { loading, generateReCaptchaToken };
};

export default useReCaptcha;
