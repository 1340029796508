import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  useTheme,
  InputAdornment,
  Grid2 as Grid,
  Typography
} from '@mui/material';
import { OfficialData, sendEmailInvites } from '../../../networkRequest/officialsService';
import { useUser } from '../../../hooks/useUser';
import EmailInviteChips from './EmailInviteChips';
import { EmailIcon } from '../../../assets/icons/icons';
import surePassLogo from '../../../assets/surePassLogoHoriztonalColor.svg';
import googleStoreBadge from '../../../assets/googleStoreBadge.png';
import appleStoreBadge from '../../../assets/appleStoreBadge.png';

type EmailInviteProps = {
  open: boolean;
  onClose: () => void;
  onSubmit?: (ids: number[]) => void;
  onError: (error: string) => void;
  availableRecipients: OfficialData[];
  initRecipients: OfficialData[];
};

export const EmailInviteDialog = ({
  open,
  onClose = () => {},
  onSubmit = () => {},
  onError,
  availableRecipients,
  initRecipients
}: EmailInviteProps) => {
  const user = useUser();
  const theme = useTheme();
  const padding = theme.spacing(4);
  const [recipients, setRecipients] = useState<OfficialData[]>(initRecipients);

  useEffect(() => {
    setRecipients(initRecipients);
  }, [initRecipients]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!user) {
      throw new Error('User not found');
    }
    const response = await sendEmailInvites(
      user.accessToken,
      recipients.map((r) => r.id)
    );
    if (response.error) {
      onError(response.error);
    } else if (response.data) {
      onSubmit?.(response.data);
    }
  };

  const fieldStyle = {
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: theme.palette.text.primary,
      color: theme.palette.text.primary
    },
    '& .MuiInputLabel-root.Mui-disabled': {
      color: theme.palette.text.primary
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth sx={{ height: '100%' }}>
      <form onSubmit={handleSubmit} role="form">
        <DialogTitle sx={{ paddingX: padding, paddingTop: padding, paddingBottom: 2 }}>
          Email Invitations
        </DialogTitle>
        <DialogContent sx={{ padding }}>
          <Box mb={3}>
            {/* Recipients Field */}
            <EmailInviteChips
              availableRecipients={availableRecipients}
              initRecipients={initRecipients}
              onRecipientsChange={setRecipients}
              autoFocus
            />
          </Box>
          <Box mb={3}>
            {' '}
            {/* Subject Field */}
            <TextField
              fullWidth
              label="Subject"
              value="Welcome to SurePass – Easy, Seamless, and Secure Access Awaits! 🚀"
              disabled
              sx={fieldStyle}
            />
          </Box>
          <Box mb={1}>
            {' '}
            {/* Email Content Field */}
            <TextField
              fullWidth
              multiline
              rows={18}
              label="Email Content"
              disabled
              sx={fieldStyle}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start" sx={{ height: 'auto', width: '100%' }}>
                      <Grid
                        container
                        size={12}
                        spacing={2}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          color: theme.palette.text.primary,
                          maxWidth: '100%',
                          overflowX: 'auto',
                          fontSize: '11pt'
                        }}
                      >
                        <Typography variant="h6" sx={{ fontSize: '14pt' }}>
                          Your SurePass Journey Starts Here
                        </Typography>
                        <p>Hi, &lt;Official Name&gt;</p>
                        <p>Welcome to SurePass – we’re excited to have you on board! 🎉</p>
                        <p>To get started Download the SurePass App:</p>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                          <Box
                            component="img"
                            src={googleStoreBadge}
                            alt="Google Play Store Badge"
                            sx={{ width: 'auto', height: 38, marginRight: 1 }}
                          />
                          <Box
                            component="img"
                            src={appleStoreBadge}
                            alt="Apple Store Badge"
                            sx={{ width: 'auto', height: 38 }}
                          />
                        </Box>
                        <p>
                          💡Pro Tip: Once you download the app, log in with your email &lt;your
                          email&gt; to view your Official Capacity Identification.
                        </p>
                        <p>
                          If you need help or have questions, our support team is just a click away
                          at{' '}
                          <Typography
                            component="span"
                            sx={{
                              color: theme.palette.info.light,
                              display: 'inline',
                              fontSize: '10pt'
                            }}
                          >
                            support@surepass.com
                          </Typography>
                          .
                        </p>
                        <p>Cheers,</p>
                        <p>The SurePass Team</p>
                        <Box
                          component="img"
                          src={surePassLogo}
                          alt="SurePass logo"
                          sx={{
                            width: '15%',
                            height: 'auto'
                          }}
                        />
                      </Grid>
                    </InputAdornment>
                  )
                }
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding, pt: 0, mr: 1 }}>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Discard
          </Button>
          <Button
            type="submit"
            variant="contained"
            startIcon={<EmailIcon />}
            disabled={recipients.length === 0}
          >
            Send Invitation ({recipients.length})
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EmailInviteDialog;
