import {
  Grid2 as Grid,
  useTheme,
  alpha,
  Card,
  CardContent,
  CardActions,
  useMediaQuery
} from '@mui/material';
import ArrowButtonLink from './ArrowButtonlink';
import OfficialsOnsite from './Blocks/OfficialsOnsite';
import { TabletSheildIcon } from '../../../assets/icons/icons';
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import { WhereToVoteOutlined as CheckPinIcon } from '@mui/icons-material';
import LinkBlock from './Blocks/LinkBlock';
import checkpointsBackground from '../../../assets/landingCheckpoints.png';
import locationsBackground from '../../../assets/landingLocations.png';
import accessLogsBackground from '../../../assets/landingAccessLogs.png';
import headerBackground from '../../../assets/landingHeader.png';
import { useEffect, useState } from 'react';
import { getData } from '../utils/data';
import { useUser } from '../../../hooks/useUser';
import Toast from '../../../components/toast/Toast';
import { Link } from 'react-router-dom';

const LandingPageBlocks = () => {
  const user = useUser();
  const theme = useTheme();
  const clearBlue = alpha(theme.palette.skyBlue || theme.palette.primary.main, 0.2);
  const titleLineHeight = 1.1;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [stats, setStats] = useState({
    total_officials: 0,
    total_armed_officials: 0,
    total_clearances_denied: 0
  });

  useEffect(() => {
    const fetchStats = async () => {
      if (!user) return;
      try {
        const data = await getData(user);
        setStats(data);
      } catch (error: any) {
        setError(error.message);
      }
      setLoading(false);
    };

    fetchStats();
  }, [user]);

  return (
    <>
      {error && (
        <Toast type="error" onClose={() => setError('')}>
          {error}
        </Toast>
      )}
      <Grid
        gap={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Grid
          width="100%"
          container
          spacing={2}
          padding={3}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: theme.palette.powderBlue,
            borderRadius: theme.shape.borderRadius / 2,
            backgroundImage: `url(${headerBackground})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <OfficialsOnsite
            stat={stats.total_officials}
            square={true}
            textColors={[theme.palette.primary.main, theme.palette.brightTeal!]}
            borderColors={[theme.palette.primary.main, theme.palette.brightTeal!]}
            backgroundColor={theme.palette.secondary.main}
            loading={loading}
          >
            <Grid sx={{ padding: 1, paddingTop: 0 }}>
              <p style={{ lineHeight: titleLineHeight, color: theme.palette.background.default }}>
                Officials
              </p>
              <p style={{ lineHeight: titleLineHeight, color: theme.palette.background.default }}>
                On-site
              </p>
            </Grid>
          </OfficialsOnsite>
          <OfficialsOnsite
            stat={stats.total_armed_officials}
            square={true}
            textColors={[theme.palette.primary.main, theme.palette.brightTeal!]}
            borderColors={[theme.palette.primary.main, theme.palette.brightTeal!]}
            backgroundColor={theme.palette.secondary.main}
            loading={loading}
          >
            <Grid sx={{ color: theme.palette.background.default, padding: 1, paddingTop: 0 }}>
              <p style={{ lineHeight: titleLineHeight }}>Armed</p>
              <p style={{ lineHeight: titleLineHeight }}>Officials</p>
              <p style={{ lineHeight: titleLineHeight }}>On-site</p>
            </Grid>
          </OfficialsOnsite>
          <OfficialsOnsite
            stat={stats.total_clearances_denied}
            square={false}
            textColors={
              stats.total_clearances_denied > 0
                ? [theme.palette.error.main]
                : [theme.palette.primary.main, theme.palette.brightTeal!]
            }
            borderColors={
              stats.total_clearances_denied > 0
                ? [theme.palette.error.main]
                : [theme.palette.primary.main, theme.palette.brightTeal!]
            }
            backgroundColor={
              stats.total_clearances_denied > 0 ? clearBlue : theme.palette.secondary.main
            }
            loading={loading}
          >
            <Grid
              sx={{
                color:
                  stats.total_clearances_denied > 0
                    ? theme.palette.error.main
                    : theme.palette.background.default,
                padding: 1,
                paddingTop: 0
              }}
            >
              <p style={{ lineHeight: titleLineHeight }}>Officials</p>
              <p style={{ lineHeight: titleLineHeight }}>Not Cleared</p>
              <p style={{ lineHeight: titleLineHeight }}>For Entry</p>
            </Grid>
          </OfficialsOnsite>
          <Link
            to="/manage-officials"
            style={{ textDecoration: 'none', maxHeight: '250px' }}
            data-testid="link"
          >
            <Card
              variant="outlined"
              sx={{
                border: `3px solid ${theme.palette.skyBlue}`,
                backgroundColor: clearBlue,
                height: '21vh',
                minHeight: '180px',
                maxHeight: '220px',
                borderRadius: 2,
                padding: 1
              }}
            >
              <CardContent sx={{ paddingTop: 1 }}>
                <div style={{ fontSize: '16pt' }}>
                  <p style={{ lineHeight: 1.25 }}>Manage</p>
                  <p style={{ lineHeight: 1.25 }}>Officials</p>
                </div>
              </CardContent>
              <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
                <ArrowButtonLink />
              </CardActions>
            </Card>
          </Link>
        </Grid>
        <Grid
          width="100%"
          container
          spacing={2}
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          {/* we can do some work optimizing these images in the future */}
          <LinkBlock title="Access Logs" link="/access-logs" background={accessLogsBackground}>
            <LockClockOutlinedIcon sx={{ color: theme.palette.brightTeal, fontSize: '26pt' }} />
          </LinkBlock>
          <LinkBlock title="Locations" link="/locations" background={locationsBackground}>
            <CheckPinIcon sx={{ color: theme.palette.brightTeal, fontSize: '26pt' }} />
          </LinkBlock>
          <LinkBlock title="Checkpoints" link="/checkpoints" background={checkpointsBackground}>
            <TabletSheildIcon sx={{ fontSize: '26pt' }} />
          </LinkBlock>
        </Grid>
      </Grid>
    </>
  );
};

export default LandingPageBlocks;
