import { UserState } from "../../../hooks/useUser";
import { Checkpoint, fetchCheckpoints } from '../../../networkRequest/checkpointsService';
import { Location, fetchAgencyLocations } from "../../../networkRequest/locationsService";
import { byId } from "../../../utils/misc";

export type CheckpointData = {
  locations: Location[];
  checkpoints: Checkpoint[];
  locationsById: Record<number, Location>;
  checkpointsById: Record<number, Checkpoint>;
  checkpointsByLocationId: Record<number, Checkpoint[]>;
}

export const getData = async (user: UserState): Promise<CheckpointData> => {
  const { data, error } = 
    await fetchAgencyLocations(user.accessToken, user.profile.agency.id);
  if (error) {
    throw new Error(error);
  } else if (!data) {
    throw new Error('No data returned');
  }
  const checkpoints = await Promise.all(
    data.map((location) => fetchCheckpoints(user.accessToken, location.id))
  ).then((results) => results.map((r) => r.data).flat().filter(r => !!r));
  const checkpointsByLocationId = checkpoints.reduce((acc, checkpoint) => {
    if (!acc[checkpoint.location.id]) {
      acc[checkpoint.location.id] = [];
    }
    acc[checkpoint.location.id].push(checkpoint);
    return acc;
  }, {} as Record<number, Checkpoint[]>);
  return { 
    locations: data,
    checkpoints,
    locationsById: byId(data),
    checkpointsById: byId(checkpoints),
    checkpointsByLocationId
  };
};
