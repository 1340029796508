import { getReq, putReq, postReq, deleteReq } from './api';
import { Location } from './locationsService';

export type DeviceConfig = {
  id: number;
  checkpoint_id: number;
  configured_by_id: number;
  configured_at: string;
  device_id: number;
  device_name: string;
  device_uuid: string;
  configured_by: string;
};

export type Checkpoint = {
  id: number;
  name: string;
  description: string;
  location: Location;
  device_configurations: DeviceConfig[];
};

export const fetchCheckpoints = async (
  accessToken: string,
  locationId: number
): Promise<{ data?: Checkpoint[]; error?: string }> => {
  return getReq<Checkpoint[]>(`/locations/${locationId}/checkpoints`, accessToken);
};

export const addCheckpoint = async (
  accessToken: string,
  checkpoint: Checkpoint
): Promise<{ data?: Checkpoint; error?: string }> => {
  return postReq<Checkpoint>(
    `/locations/${checkpoint.location.id}/checkpoints`,
    accessToken,
    checkpoint
  );
};

export const updateCheckpoint = async (
  accessToken: string,
  checkpoint: Checkpoint
): Promise<{ data?: Checkpoint; error?: string }> => {
  return putReq<Checkpoint>(`/checkpoints/${checkpoint.id}`, accessToken, checkpoint);
};

export const deleteCheckpoint = async (
  accessToken: string,
  checkpointId: number
): Promise<{ error?: string }> => {
  return deleteReq(`/checkpoints/${checkpointId}`, accessToken);
};

export const addCheckpointDevice = async (
  accessToken: string,
  checkpointId: number,
  deviceId: number
): Promise<{ data?: DeviceConfig; error?: string }> => {
  return postReq<DeviceConfig>(`/checkpoints/${checkpointId}/devices`, accessToken, {
    device_id: deviceId
  });
};
