import { Box, Container, Divider, Typography, useTheme } from '@mui/material';
import LandingPageBlocks from '../features/landingPage/components/LandingPageBlocks';
import { useUser } from '../hooks/useUser';

const LandingPage = () => {
  const theme = useTheme();
  const user = useUser();

  if (!user) return;

  return (
    <Box component={'main'} gridArea={'main'}>
      <Container sx={{ height: '100%' }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, mb: 2 }}
          color={theme.palette.text.primary}
        >
          <Typography variant="h5" sx={{ mb: 1 }}>
            Welcome, {user?.profile.agency.name}
          </Typography>
          <Divider />
        </Box>
        <LandingPageBlocks />
      </Container>
    </Box>
  );
};

export default LandingPage;
