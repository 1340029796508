import { Box, Card, CardContent, Skeleton, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface OfficialsOnsite {
  stat: number;
  textColors: [string] | [string, string];
  borderColors: [string] | [string, string];
  backgroundColor: string;
  square: boolean;
  loading: boolean;
  children: ReactNode;
}

const OfficialsOnsite = ({
  stat,
  textColors,
  borderColors,
  backgroundColor,
  loading,
  children
}: OfficialsOnsite) => {
  const formatStat = (stat) => {
    // the designs request:
    // - show leading 0 before numbers between 1 and 9
    // - regular display for numbers between 10 and 699
    // - show leading 0 before decimal along with the K for anything between 700 and 999: 0.7K, 0.9K
    // - over 1000, show 1 decimal along with K: 1K, 5.6K but not if the decimal is 0?
    // - over 9999, round the number to thousanths and add K: 10K, 12K, 55K

    if (stat >= 9999) {
      return `${(stat / 1000).toFixed()}K`;
    }

    if (stat > 700 && stat <= 9999) {
      let result = (stat / 1000).toFixed(1) + 'K';
      if (result.endsWith('.0K')) {
        result = result.substring(0, result.length - 3) + 'K';
      }
      return result;
    }

    if (stat <= 9 && stat > 0) {
      return `0${stat}`;
    }

    return stat.toString();
  };

  return (
    <>
      {loading ? (
        <Skeleton variant="rounded" height="23vh">
          <Card
            variant="outlined"
            sx={{
              border: `3px solid ${borderColors[0]}`,
              backgroundColor: backgroundColor,
              height: '21vh',
              minHeight: '150px',
              maxHeight: '225px',
              borderRadius: 2
            }}
          >
            <CardContent sx={{ paddingTop: 1 }}>
              <Typography
                color={textColors[0]}
                sx={{
                  textAlign: 'center',
                  fontSize: '6rem',
                  lineHeight: '6rem',
                  fontFamily: "'Open Sans', sans-serif"
                }}
              >
                {formatStat(stat)}
              </Typography>
              {children}
            </CardContent>
          </Card>
        </Skeleton>
      ) : (
        <Box
          sx={{
            position: 'relative',
            height: '21vh',
            minHeight: '180px',
            maxHeight: '220px',
            borderRadius: 2.5,
            background:
              borderColors.length === 2
                ? `linear-gradient(235deg, ${borderColors[0]}, ${borderColors[1]})`
                : null,
            border: borderColors.length === 1 ? `3px solid ${borderColors[0]}` : null,
            boxSizing: 'border-box',
            padding: borderColors.length === 2 ? '4px' : '0'
          }}
        >
          <Card
            sx={{
              height: '100%',
              width: '100%',
              backgroundColor,
              borderRadius: 2,
              boxShadow: 'none'
            }}
          >
            <CardContent sx={{ paddingTop: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  sx={{
                    textAlign: 'right',
                    fontSize: '6rem',
                    lineHeight: '6rem',
                    fontFamily: "'Open Sans', sans-serif",
                    display: 'inline-block',
                    float: 'right',
                    WebkitBackgroundClip: textColors.length === 2 ? 'text !important' : null,
                    WebkitTextFillColor: textColors.length === 2 ? 'transparent' : null,
                    background:
                      textColors.length === 2
                        ? `linear-gradient(45deg, ${textColors[0]}, ${textColors[1]})`
                        : null,
                    color: textColors.length === 1 ? textColors[0] : null
                  }}
                >
                  {formatStat(stat)}
                </Typography>
              </Box>
              {children}
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
};

export default OfficialsOnsite;
