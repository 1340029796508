import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import CredentialItem from './CredentialItem';
import { CredentialInput, OfficialCredential } from '../../../networkRequest/officialsService';
import AddCredentialDialog from './AddCredentialDialog';
import Toast from '../../../components/toast/Toast';

type CredentialsListProps = {
  officialId: number;
  credentials: OfficialCredential[];
  refreshData: () => Promise<void>;
};

const CredentialsList = ({ officialId, credentials, refreshData }: CredentialsListProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onDialogSave = async (_c: CredentialInput) => {
    setSuccessMessage('Credential saved');
    setIsDialogOpen(false);
    refreshData();
  };

  const onError = (error: string) => {
    setErrorMessage(error);
    setIsDialogOpen(false);
  };

  const clearError = () => setErrorMessage('');
  const clearSuccess = () => setSuccessMessage('');
  return (
    <>
      <Box>
        <Box my={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Issued Credentials</Typography>
          <Button variant="contained" color="primary" onClick={() => setIsDialogOpen(true)}>
            Add New Credential
          </Button>
        </Box>
        {credentials.map((credential) => (
          <CredentialItem
            key={credential.id}
            credential={credential}
            setSuccessMessage={setSuccessMessage}
            refreshData={refreshData}
          />
        ))}
      </Box>
      {errorMessage && (
        <Toast type="error" onClose={clearError}>
          {errorMessage}
        </Toast>
      )}
      {successMessage && (
        <Toast type="success" onClose={clearSuccess}>
          {successMessage}
        </Toast>
      )}
      <AddCredentialDialog
        open={isDialogOpen}
        officialId={officialId}
        onClose={() => setIsDialogOpen(false)}
        onError={onError}
        onSubmit={onDialogSave}
      />
    </>
  );
};

export default CredentialsList;
