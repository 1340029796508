import { Box, Typography, useTheme, Grid2 as Grid } from '@mui/material';
import { useState, ReactNode } from 'react';
import Confirmation from '../../../components/confirmation/Confirmation';
import { useUser } from '../../../hooks/useUser';
import {
  CredentialInput,
  deleteCredential,
  OfficialCredential
} from '../../../networkRequest/officialsService';
import Toast from '../../../components/toast/Toast';
import SmallButton from '../../../components/SmallButton';
import EditCredentialDialog from './EditCredentialDialog';
import Status from './StatusChip';
import { Delete as TrashIcon } from '@mui/icons-material';
import SmallIconButton from '../../../components/SmallIconButton';

type FieldProps = {
  label: string;
  value: string | ReactNode;
  capitalize?: boolean;
};

type CredentialItemProps = {
  credential: OfficialCredential;
  refreshData: () => Promise<void>;
  setSuccessMessage: (string) => void;
};

const Field = ({ label, value, capitalize = true }: FieldProps) => (
  <Grid size={6} bgcolor="grey.100" py={1} px={2} borderRadius={2}>
    <Typography variant="body2" color="secondary" gutterBottom>
      {label}
    </Typography>
    <Box textTransform={capitalize ? 'capitalize' : 'none'}>{value}</Box>
  </Grid>
);

const formatDate = (date: string) => {
  const d = new Date(date);
  return d.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
};

const CredentialItem = ({ credential, refreshData, setSuccessMessage }: CredentialItemProps) => {
  const user = useUser();
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const onDialogSave = async (_c: CredentialInput) => {
    setSuccessMessage('Credential updated');
    setIsDialogOpen(false);
    refreshData();
  };

  const onError = (error: string) => {
    setErrorMessage(error);
  };

  const remove = async () => {
    if (user) {
      const response = await deleteCredential(user.accessToken, credential.official_credential_id);
      if (response.error) {
        setErrorMessage(response.error);
      }

      if (response.data) {
        setSuccessMessage(`Credential deleted`);
        await refreshData();
        setIsConfirmationOpen(false);
      }
    } else {
      setErrorMessage('Failed to delete location, user not found');
    }
  };

  const clearError = () => setErrorMessage('');

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        border={`1px solid ${theme.palette.primary.dark}`}
        borderRadius={2}
        mb={2}
        p={2}
      >
        <Grid container spacing={2} width={1}>
          <Grid size={6} px={1} py={0}>
            <Typography variant="body2" color="secondary">
              Updated On
            </Typography>
            <Typography fontWeight={600}>{formatDate(credential.updated_at)}</Typography>
          </Grid>
          <Grid size={6} display="flex" justifyContent="flex-end" gap={1}>
            <SmallButton variant="outlined" color="secondary" onClick={() => setIsDialogOpen(true)}>
              Edit Credential
            </SmallButton>
            <SmallIconButton onClick={() => setIsConfirmationOpen(true)}>
              <TrashIcon />
            </SmallIconButton>
          </Grid>
          <Field
            label="Official Credential ID"
            value={credential.official_credential_id}
            capitalize={false}
          />
          <Field label="Status" value={<Status value={credential.status} />} />
          <Field label="Client Provided" value={credential.client_provided ? 'true' : 'false'} />
          <Field label="Credential Type" value={credential.credential_type} />
        </Grid>
      </Box>
      {errorMessage && (
        <Toast type="error" onClose={clearError}>
          {errorMessage}
        </Toast>
      )}
      <EditCredentialDialog
        credential={credential}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onError={onError}
        onSubmit={onDialogSave}
      />
      <Confirmation
        open={isConfirmationOpen}
        message="Deleting this credential is a permanent action. Are you certain you want to continue?"
        onConfirm={remove}
        onCancel={() => setIsConfirmationOpen(false)}
        confirmText="Delete"
        cancelText="Cancel"
      />
    </>
  );
};

export default CredentialItem;
