import { useState, useEffect, useMemo } from 'react';
import {
  addCheckpoint,
  Checkpoint,
  updateCheckpoint
} from '../../../networkRequest/checkpointsService';
import { Location } from '../../../networkRequest/locationsService';
import { useUser } from '../../../hooks/useUser';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid2 as Grid,
  useTheme
} from '@mui/material';

type CheckpointDialogProps = {
  title?: string;
  open: boolean;
  onClose: () => void;
  location: Location;
  checkpoint?: Checkpoint; // undefined for add, defined for edit
  onSubmit?: (formData: Checkpoint) => void;
  onChange?: ({ name, value }: { name: string; value: string }) => void;
  onError: (error: string) => void;
};

const defaultCheckpointSkeleton = {
  id: 0, // or make it optional in the Checkpoint type for new entries
  name: '',
  description: '',
  device_configurations: []
};

export const EditCheckpointDialog = ({
  open,
  location,
  checkpoint: initCheckpoint,
  title = initCheckpoint ? 'Edit Checkpoint' : 'Add Checkpoint',
  onClose = () => {},
  onSubmit = () => {},
  onChange = () => {},
  onError
}: CheckpointDialogProps) => {
  const user = useUser();
  const theme = useTheme();
  const padding = theme.spacing(4);

  const defaultCheckpoint = useMemo(
    () => ({
      ...defaultCheckpointSkeleton,
      location
    }),
    [location]
  );
  const [checkpoint, setCheckpoint] = useState<Checkpoint>(initCheckpoint || defaultCheckpoint);

  useEffect(() => {
    if (initCheckpoint) {
      setCheckpoint(initCheckpoint);
    } else {
      setCheckpoint(defaultCheckpoint);
    }
  }, [initCheckpoint, defaultCheckpoint, open]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!user) {
      throw new Error('User not found');
    }
    const isEdit = Boolean(initCheckpoint);
    const data = { ...checkpoint, id: initCheckpoint?.id || checkpoint.id };
    if (data.location.id !== location.id) {
      throw new Error('Location mismatch');
    }
    const response = isEdit
      ? await updateCheckpoint(user.accessToken, data)
      : await addCheckpoint(user.accessToken, data);
    if (response.error) {
      onError(response.error);
    } else if (response.data) {
      onSubmit?.(response.data);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCheckpoint((prevState) => ({
      ...prevState,
      [name]: value
    }));
    onChange?.({ name, value });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit} role="form">
        <DialogTitle sx={{ padding }}>{title}</DialogTitle>
        <DialogContent sx={{ padding }}>
          <Grid container spacing={3} padding={1}>
            <Grid size={12}>
              <TextField
                autoFocus
                label="Name"
                name="name"
                value={checkpoint.name || ''}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid size={12}>
              <TextField
                label="Description"
                name="description"
                multiline
                rows={3}
                fullWidth
                value={checkpoint.description || ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding, pt: 0, mr: 1 }}>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditCheckpointDialog;
