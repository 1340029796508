import axios, { AxiosRequestConfig } from "axios";

type APIErrorData = { errors: string[] };

// Helper function to make requests to the API
const doRequest = async <T>(
  method: 'get' | 'post' | 'put' | 'delete',
  url: string,
  accessToken: string | undefined,
  defaultError: string = "Request failed",
  data?: unknown,
): Promise<{ data?: T; error?: string, status?: number }> => {
  const options: AxiosRequestConfig = {
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      referrerPolicy: 'strict-origin-when-cross-origin',
    }
  };

  try { 
    const path = `/api/v2${url}`;
    const response = await axios.request<{ data: T }>({
      method,
      url: path,
      data,
      ...options
    });

    if (response.status >= 200 && response.status < 300) {
      if (response.data) {
        return { data: response.data.data }
      }
      return { status: response.status };
    } else {
      return { error: `Request failed with status ${response.status}.`};
    }
  } catch (error) {
    if (axios.isAxiosError<APIErrorData>(error) && error.response) {
      if (error.response.data?.errors) {
        return { error: error.response.data.errors.join(' ') }
      }
    } 
    if (error instanceof Error) {
      return { error: error.message, status: 500 };
    }
    return { error: defaultError };
  }
};

export const getReq = async <T>(
  url: string, 
  accessToken: string | undefined, 
  defaultError: string = "Failed to fetch",
) => doRequest<T>('get', url, accessToken, defaultError);

export const postReq = async <T>(
  url: string,
  accessToken: string | undefined,
  data: unknown,
  defaultError: string = "Failed to post",
) => doRequest<T>('post', url, accessToken, defaultError, data);

export const putReq = async <T>(
  url: string,
  accessToken: string,
  data: unknown,
  defaultError: string = "Failed to update",
) => doRequest<T>('put', url, accessToken, defaultError, data);

export const deleteReq = async <T>(
  url: string,
  accessToken: string,
  defaultError: string = "Failed to delete",
) => doRequest<T>('delete', url, accessToken, defaultError);
