import { useEffect, useState, useCallback, useMemo } from 'react';
import { DataGrid, GridRowModel } from '@mui/x-data-grid';
import { Box, useTheme } from '@mui/material';
import { Agency } from '../../../networkRequest/agenciesService';
import EditAgencyOfficialsDialog from './EditAgencyOfficialsDialog';
import EmailInviteDialog from './EmailInviteDialog';
import { createOfficialsColumns } from './agencyOfficialsColumns';
import Toast from '../../../components/toast/Toast';
import { useUser } from '../../../hooks/useUser';
import { flattenOfficials } from './officialFormatter';
import AddAgencyOfficialDialog from './AddAgencyOfficialDialog/AddAgencyOfficialDialog';
import CustomToolBar from '../../../components/CustomToolBar';
import DataTableButton from '../../../components/buttons/dataTableTopButton';
import { EmailIcon } from '../../../assets/icons/icons';
import { getAgenciesData, getAgencyData, getOfficialsData } from '../utils/data';
import { employmentTypeOptions, OfficialData } from '../../../networkRequest/officialsService';
import { byId } from '../../../utils/misc';

export interface EditOfficialPromiseInfo {
  resolve: (row: GridRowModel) => void;
  reject: (row: GridRowModel) => void;
  updatedRow: GridRowModel;
  originalRow: GridRowModel;
}

export interface RowMutations {
  updatedValue: string | number | number[] | { id: number; name: string } | null;
  editedKey: string;
  updatedLabel: string;
  originalLabel: string;
}

export type AgencyOfficialRolesMap = Map<string, number>;
export type AgencyOfficialTypesMap = Map<number, { name: string; id: number }>;

const initialRowMutationState: RowMutations = {
  updatedValue: '',
  editedKey: '',
  updatedLabel: '',
  originalLabel: ''
};

export const AgencyOfficialsDataTable: React.FC = () => {
  const user = useUser();
  const theme = useTheme();

  // Data Table State
  const [agencyOfficialTypes, setAgencyOfficialsTypes] = useState<AgencyOfficialTypesMap>(
    new Map()
  );
  const [agencyOfficialTypesByName, setAgencyOfficialTypesByName] =
    useState<AgencyOfficialRolesMap>(new Map());
  const [agencyOfficialRoles, setAgencyOfficialRoles] = useState<AgencyOfficialRolesMap>(new Map());
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Officials Asynchronous Handling
  const [officials, setOfficials] = useState<OfficialData[]>([]);
  const [officialsError, setOfficialsError] = useState('');
  const [officialsLoading, setOfficialsLoading] = useState(false);

  // Single Agency Asynchronous Handling
  const [agencyData, setAgencyData] = useState<Agency | null>(null);
  const [agencyError, setAgencyError] = useState('');
  const [agencyLoading, setAgencyLoading] = useState(true);

  // All Agencies Asynchronous Handling
  const [agencies, setAgencies] = useState<Agency[]>([]);
  const [agenciesError, setAgenciesError] = useState('');
  const [agenciesLoading, setAgenciesLoading] = useState(true);

  // Edit Official State
  const [editOfficialPromiseInfo, setEditOfficialPromiseInfo] =
    useState<EditOfficialPromiseInfo | null>(null);
  const [rowMutations, setRowMutations] = useState<RowMutations>(initialRowMutationState);

  // Add Official State
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  // Email Invite State
  const [isEmailInviteDialogOpen, setIsEmailInviteDialogOpen] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<OfficialData[]>([]);

  // Store lookup for officials by id
  const officialsById = useMemo(() => byId(officials), [officials]);

  // note for future us: alternative to the repetitive async code below is custom hook or library such as tanstack query, etc.
  const fetchOfficials = useCallback(async () => {
    setOfficialsLoading(true);
    if (!user) return;
    try {
      const data = await getOfficialsData(user);
      setOfficials(data);
    } catch (error: any) {
      setOfficialsError(error.message);
    }
    setOfficialsLoading(false);
  }, [user]);

  useEffect(() => {
    fetchOfficials();
  }, [fetchOfficials]);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;

      try {
        const data = await getAgencyData(user);
        setAgencyData(data);
      } catch (error: any) {
        setAgencyError(error.message);
      }
      setAgencyLoading(false);
    };
    fetchData();
  }, [setAgencyError, user]);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;

      try {
        const data = await getAgenciesData(user);
        setAgencies(data);
      } catch (error: any) {
        setAgenciesError(error.message);
      }
      setAgenciesLoading(false);
    };

    fetchData();
  }, [setAgenciesError, user]);

  const columns = useMemo(
    () => createOfficialsColumns(agencyOfficialTypes, agencyOfficialRoles, theme),
    [agencyOfficialTypes, agencyOfficialRoles, theme]
  );

  useEffect(() => {
    if (agencyData) {
      const byIdType = new Map<number, { id: number; name: string }>(
        agencyData.agency_official_types.map((type) => [type.id, type])
      );

      const byNameRoles = new Map<string, number>(
        agencyData.roles.map((type) => [type.name, type.id])
      );

      const byNameTypes = new Map<string, number>(
        agencyData.agency_official_types.map((type) => [type.name, type.id])
      );

      setAgencyOfficialsTypes(byIdType);
      setAgencyOfficialRoles(byNameRoles);
      setAgencyOfficialTypesByName(byNameTypes);
    }
  }, [agencyData]);

  const allLoading = loading || officialsLoading || agencyLoading || agenciesLoading;

  const errorMessages = [errorMessage, officialsError, agencyError, agenciesError];
  const hasAnyError = errorMessages.some((msg) => msg !== '');

  const clearAllErrors = () => {
    setErrorMessage('');
    setOfficialsError('');
    setAgencyError('');
    setAgenciesError('');
  };

  useEffect(() => {
    if (!user && !allLoading) {
      setErrorMessage('Login Required');

      return;
    }
  }, [user, allLoading]);

  const CustomizedCustomToolBar = (): JSX.Element => {
    return (
      <CustomToolBar buttonText="Add Official" onAddClick={openAgencyOfficialDialog}>
        <DataTableButton
          startIcon={<EmailIcon />}
          buttonText="Email Invite"
          onClick={() => setIsEmailInviteDialogOpen(true)}
        />
      </CustomToolBar>
    );
  };

  const resetRowMutations = useCallback(() => setRowMutations(initialRowMutationState), []);

  const calculateRowEdit = useCallback(
    (updatedRow: GridRowModel, originalRow: GridRowModel) => {
      for (const key in updatedRow) {
        // linter dislikes hasOwnProperty usage and es2022 hasOwn is unavailable in the current environment,
        // so 'key in updatedRow' may work for now.
        if (key in updatedRow) {
          // I couldn't think of a better way to do this. What we show a user isn't the data that we need to update the db for the matching data, so we have to do some kind of mapping. Have any better ideas?
          // the labels in the mutations are what display in the confirmation dialog.
          if (updatedRow[key] !== originalRow[key]) {
            const mutation: RowMutations = { ...initialRowMutationState };
            const selectedOption = employmentTypeOptions.find(
              (option) => option.value == updatedRow[key]
            );
            const originalOption = employmentTypeOptions.find(
              (option) => option.value == originalRow[key]
            );

            switch (key) {
              case 'agency_official_type_id':
                mutation.updatedValue = agencyOfficialTypesByName.get(updatedRow[key]) || null;
                mutation.editedKey = 'agency_official_type_id';
                mutation.updatedLabel = updatedRow[key];
                mutation.originalLabel = agencyOfficialTypes.get(originalRow[key])?.name || 'None';
                break;
              case 'employment_type':
                if (!selectedOption || !originalOption) {
                  break;
                }
                mutation.updatedLabel = selectedOption.label;
                mutation.originalLabel = originalOption.label;
                mutation.updatedValue = selectedOption.value;
                mutation.editedKey = 'employment_type';
                break;
              case 'role':
                mutation.updatedValue = agencyOfficialRoles.get(updatedRow[key])!;
                mutation.editedKey = 'role_id';
                mutation.updatedLabel = updatedRow[key];
                mutation.originalLabel = originalRow[key].name;
                break;
              default:
                mutation.updatedValue = updatedRow[key];
                mutation.updatedLabel = updatedRow[key];
                mutation.originalLabel = originalRow[key];
                mutation.editedKey = key;
            }
            setRowMutations(mutation);
            // relying on true/false return for this function so that we don't have to fight
            // async state updates in order to proceed with following code.
            return true;
          }
        }
      }

      resetRowMutations();
      return false;
    },
    [resetRowMutations, agencyOfficialTypesByName, agencyOfficialTypes, agencyOfficialRoles]
  );

  const openAgencyOfficialDialog = () => {
    setIsDialogOpen(true);
  };

  const closeAgencyOfficialDialog = () => {
    setIsDialogOpen(false);
    // want to have latest officials in case they added someone partially or someone else added one to avoid duplicates?
    fetchOfficials();
  };

  const processRowUpdate = useCallback(
    (updatedRow: GridRowModel, originalRow: GridRowModel) => {
      return new Promise<GridRowModel>((resolve, reject) => {
        const handleUpdate = async () => {
          try {
            setLoading(true);
            const rowEdits = calculateRowEdit(updatedRow, originalRow);
            if (rowEdits) {
              setEditOfficialPromiseInfo({ resolve, reject, updatedRow, originalRow });
            } else {
              resolve(originalRow);
            }
          } catch (error) {
            setErrorMessage('Something went wrong: ' + error);
            reject(error);
          } finally {
            setLoading(false);
          }
        };
        handleUpdate();
      });
    },
    [calculateRowEdit]
  );

  const handleProcessRowUpdateError = useCallback(() => {
    setEditOfficialPromiseInfo(null);
    resetRowMutations();
    setErrorMessage('Error processing official edit.');
  }, [resetRowMutations]);

  const onSendEmailInvites = () => {
    setSuccessMessage('Emails sent');
    setIsEmailInviteDialogOpen(false);
    // we are optimistically updating these officials
    const invitedOfficials = selectionModel.map(
      (invitedOfficial) => (invitedOfficial.invite_status = 'sent')
    );
    invitedOfficials.forEach((updatedOfficial) => {
      saveSingleOfficialEdit(updatedOfficial);
    });
    setSelectionModel([]);
  };

  const saveSingleOfficialEdit = (updatedOfficial) => {
    setOfficials((previousState) =>
      previousState.map((official) => {
        if (official.id === updatedOfficial.id) return updatedOfficial;
        return official;
      })
    );
  };

  return (
    <>
      {
        <EditAgencyOfficialsDialog
          open={!!editOfficialPromiseInfo}
          editOfficialPromiseInfo={editOfficialPromiseInfo}
          setEditOfficialPromiseInfo={setEditOfficialPromiseInfo}
          rowMutations={rowMutations}
          saveSingleOfficialEdit={saveSingleOfficialEdit}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          resetRowMutations={resetRowMutations}
          rolesMap={agencyOfficialRoles}
        />
      }
      {
        <EmailInviteDialog
          open={isEmailInviteDialogOpen}
          onClose={() => setIsEmailInviteDialogOpen(false)}
          onError={setErrorMessage}
          onSubmit={onSendEmailInvites}
          availableRecipients={officials}
          initRecipients={selectionModel}
        />
      }
      {hasAnyError && (
        <Toast type="error" onClose={clearAllErrors}>
          {errorMessages.filter((msg) => msg !== '').join('\n')}
        </Toast>
      )}
      {successMessage && (
        <Toast type="success" onClose={() => setSuccessMessage('')}>
          {successMessage}
        </Toast>
      )}
      <Box sx={{ flexGrow: 1, height: '100%' }}>
        <DataGrid
          data-testid="agency-officials"
          columns={columns}
          rows={flattenOfficials(officials)}
          loading={allLoading}
          disableRowSelectionOnClick
          checkboxSelection
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          onRowSelectionModelChange={(newSelection) =>
            setSelectionModel(newSelection.map((id) => officialsById[id]!))
          }
          slots={{
            toolbar: () => <CustomizedCustomToolBar data-testid="agency-officials-toolbar" />
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton'
            }
          }}
          sx={{
            '--DataGrid-overlayHeight': '300px',
            height: '100%',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '600'
            },
            '& .MuiDataGrid-toolbarContainer': {
              backgroundColor: '#E3F2FD'
            },
            '& .MuiDataGrid-columnHeaderTitle, & .MuiButton-text, & [data-testid="IndeterminateCheckBoxIcon"], & [data-testid="CheckBoxIcon"], & [data-testid="CheckBoxOutlineBlankIcon"]':
              {
                color: 'navyBlue'
              },
            '& .MuiDataGrid-overlayWrapperInner': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }
          }}
        />
      </Box>
      <AddAgencyOfficialDialog
        open={isDialogOpen}
        onClose={closeAgencyOfficialDialog}
        types={agencyOfficialTypes}
        typesByName={agencyOfficialTypesByName}
        roles={agencyOfficialRoles}
        agencies={agencies}
        setDialogOpen={setIsDialogOpen}
      />
    </>
  );
};

export default AgencyOfficialsDataTable;
