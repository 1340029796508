import axios from 'axios';
import { getReq } from './api';

export interface AgencyOfficialType {
  id: number;
  name: string;
  agency_id: number;
}

export interface AgencyOfficialRole {
  id: number;
  name: string;
  description: string;
}

export interface Agency {
  id: number;
  name: string;
  logoUrl: string;
  groups: AgencyGroup[];
  agency_official_types: AgencyOfficialType[];
  roles: AgencyOfficialRole[];
}

export interface AgencyGroup {
  id: number;
  name: string;
  default: boolean;
  agency_id: number;
}

export interface AgencyRole {
  id: number;
  name: string;
}

export const getAgencies = async (
  accessToken: string
): Promise<{ data?: Agency[]; error?: string }> => {
  return getReq<Agency[]>('/agencies', accessToken);
};

export const getAgencyNames = async (): Promise<{ data?: string[]; error?: string }> => {
  return getReq<string[]>('/agency_names', undefined);
};

export const getAgency = async (
  accessToken: string,
  agencyId: number
): Promise<{ data?: Agency; error?: string }> => {
  return getReq<Agency>(`/agencies/${agencyId}`, accessToken);
};

export const getAgencyGroups = async (accessToken: string, agencyId: number) => {
  try {
    const response = await axios.get<{ data: AgencyGroup[] }>(
      `/api/v2/agencies/${agencyId}/groups`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return { data: response.data.data };
  } catch (error: any) {
    return { error: error.message || 'An unkown error occured' };
  }
};
