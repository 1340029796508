import { getReq } from './api';

export interface AgencyStats {
  total_officials: number;
  total_armed_officials: number;
  total_clearances_denied: number;
}

export const fetchAgencyStats = async (
  accessToken: string,
  agencyId: number
): Promise<{ data?: AgencyStats; error?: any }> => {
  return getReq<AgencyStats>(`/agencies/${agencyId}/stats`, accessToken, 'Failed to fetch stats.');
};
