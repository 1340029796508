import {
  alpha,
  Box,
  Button,
  Grid2 as Grid,
  List,
  ListItem,
  Typography,
  useTheme
} from '@mui/material';
import { UploadIcon } from '../../assets/icons/icons';
import { useDropzone } from 'react-dropzone';
import React, { useCallback, useEffect, useMemo } from 'react';
import { validateOfficialImage } from '../../utils/dropzoneImageValidator';
import AddAgencyOfficialDialogUploadFileView, {
  PreviewDropzoneFile
} from './AddAgencyOfficialDialogUploadFileView';
import copy from '../../containers/pageLayout/definitions/copy';

export interface DropzoneFile extends File {
  path?: string;
}

interface UploadDropzoneProps {
  showPreview: boolean;
  file: PreviewDropzoneFile | null;
  setFile:
    | React.Dispatch<React.SetStateAction<PreviewDropzoneFile | null>>
    | ((acceptedFile: PreviewDropzoneFile | null) => Promise<void>);
  clearSelectedFile: React.Dispatch<React.SetStateAction<PreviewDropzoneFile | null>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setCanContinue: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage: string;
  buttonOnly?: boolean;
}

const UploadDropzone = ({
  file,
  showPreview,
  setFile,
  clearSelectedFile,
  loading,
  setLoading,
  setErrorMessage,
  setCanContinue,
  errorMessage,
  buttonOnly = false
}: UploadDropzoneProps) => {
  const theme = useTheme();

  const onDropAccepted = useCallback(
    async (acceptedFile) => {
      setErrorMessage('');
      setLoading(true);
      setFile(Object.assign(acceptedFile[0], { preview: URL.createObjectURL(acceptedFile[0]) }));

      setTimeout(() => {
        setLoading(false);
        setCanContinue(true);
      }, 1000);
    },
    [setCanContinue, setErrorMessage, setFile, setLoading]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, fileRejections } =
    useDropzone({
      onDropAccepted,
      maxFiles: 1,
      validator: validateOfficialImage
    });

  const style = useMemo(() => {
    const focusedStyle = {
      borderColor: theme.palette.skyBlue
    };

    const acceptStyle = {
      borderColor: theme.palette.skyBlue,
      backgroundColor: theme.palette.grey[200]
    };

    const rejectStyle = {
      borderStyle: 'solid',
      borderColor: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.light, 0.2)
    };

    return {
      flex: 1,
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(3),
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.palette.grey[500],
      borderStyle: 'dashed',
      color: theme.palette.background.default,
      outline: 'none',
      transition: 'border .24s ease-in-out',
      cursor: 'pointer',
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    };
  }, [isFocused, isDragAccept, isDragReject, theme]);

  useEffect(() => {
    if (fileRejections.length > 0) setErrorMessage(fileRejections[0].errors[0].message);
  }, [fileRejections, setErrorMessage]);

  if (buttonOnly && !showPreview) {
    return (
      <Grid {...getRootProps()} container sx={{ width: '100%' }}>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Typography variant="caption" textAlign="center">
            SurePass requires a recent photo for in-person identity verification.
            <List>
              <ListItem sx={{ paddingY: 0 }}>- {copy.imageUpload.step1}</ListItem>
              <ListItem sx={{ paddingY: 0 }}>- {copy.imageUpload.step2}</ListItem>
              <ListItem sx={{ paddingY: 0 }}>- {copy.imageUpload.step3}</ListItem>
              <ListItem sx={{ paddingY: 0 }}>- {copy.imageUpload.fileType}</ListItem>
            </List>
          </Typography>
          <Box>
            <input style={{ display: 'none' }} {...getInputProps()} />

            <Button variant="outlined" color="secondary" component="label" sx={{ margin: 2 }}>
              <UploadIcon sx={{ width: 14 }} />
              Upload Photo
            </Button>
          </Box>
          <p style={{ color: theme.palette.error.main }}>{errorMessage}</p>
        </Grid>
      </Grid>
    );
  }

  if (showPreview) {
    return (
      <AddAgencyOfficialDialogUploadFileView
        file={file}
        clearCurrentFile={clearSelectedFile}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        uploadLoading={loading}
      />
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Grid
          gap={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <UploadIcon
            sx={{
              fontSize: 36,
              color: errorMessage ? theme.palette.error.main : theme.palette.grey[700]
            }}
          />

          <p style={{ fontSize: '14pt', color: theme.palette.primary.dark }}>
            <span style={{ color: theme.palette.darkTeal, textDecoration: 'underline' }}>
              Click to upload photo
            </span>{' '}
            or drag and drop
          </p>
          <p style={{ color: theme.palette.error.main }}>{errorMessage}</p>
          <p style={{ fontSize: '14pt', color: theme.palette.primary.dark }}>
            PNG, JPG, or HEIC (max. 3MB)
          </p>
        </Grid>
      </div>
    </Box>
  );
};

export default UploadDropzone;
