import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Avatar, Box, CssBaseline, Drawer, IconButton, useMediaQuery } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Menu, MenuOpen } from '@mui/icons-material';
import Sidebar from '../Sidebar';
import appBarLogo from '../../assets/SurePass Logo Horz Black.svg';
import { useUser } from '../../hooks/useUser';

const drawerWidth = 291;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: `${drawerWidth}px`
  })
}));

const SidebarContainer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.secondary.main
  }
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const PageLayout = () => {
  const [open, setOpen] = useState<boolean>(true);
  const isSmallScreen = useMediaQuery('(max-width:1024px)');

  useEffect(() => {
    setOpen(!isSmallScreen);
  }, [isSmallScreen]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const AppBarHeader = () => {
    const user = useUser();
    return (
      <AppBar
        position="sticky"
        color="default"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          height: '65px',
          maxWidth: '1024px',
          padding: '0 12px'
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" padding="8px">
          <IconButton onClick={toggleDrawer}>{open ? <MenuOpen /> : <Menu />}</IconButton>
          <Box component="img" src={appBarLogo} alt="SurePass logo" />
          <Avatar src={user?.profile.img_url} alt="User picture" />
        </Box>
      </AppBar>
    );
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
      <CssBaseline />
      {isSmallScreen && <AppBarHeader />}
      <SidebarContainer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Sidebar />
      </SidebarContainer>
      <Main open={open}>
        <Outlet /> {/* Nested routes will render here */}
      </Main>
    </Box>
  );
};

export default PageLayout;
