import axios, { AxiosRequestConfig } from 'axios';
import { postReq } from './api';

const cloudinaryAPIBaseUrl = 'https://api.cloudinary.com/v1_1';
const cloudinary_cloud_name = import.meta.env.VITE_CLOUDINARY_CLOUD_NAME;
const official_img_preset = import.meta.env.VITE_CLOUDINARY_OFFICIAL_IMG_PRESET;
const public_official_img_preset = import.meta.env.VITE_CLOUDINARY_PUBLIC_OFFICIAL_IMG_PRESET;

export interface PartialCloudinaryImageAsset {
  secure_url: string;
  existing: boolean;
  url: string;
  bytes: number;
  access_mode: string;
  public_id: string;
}

interface CloudinaryUploadSignature {
  signature: string;
  api_key: string;
}

const getUploadSignature = async (
  accessToken: string,
  uploadFormData: FormData
): Promise<{ data?: { signature: string; api_key: string }; error?: any }> => {
  return postReq<CloudinaryUploadSignature>(
    `/cloudinary_signatures`,
    accessToken,
    uploadFormData,
    'Failed to fetch cloudinary upload signature'
  );
};

const generateUploadFormData = async (
  file: File,
  accessToken: string,
  agencyId: number
): Promise<FormData> => {
  const formData = new FormData();
  formData.append('upload_preset', official_img_preset);
  const timestamp = String(Math.floor(Date.now() / 1000));
  formData.append('timestamp', timestamp);
  formData.append('folder', `${agencyId}/official_images`);

  const { data, error } = await getUploadSignature(accessToken, formData);

  if (error) {
    throw new Error(error);
  } else if (data) {
    formData.append('signature', data.signature);
    formData.append('api_key', data.api_key);
    formData.append('file', file);
  }

  return formData;
};

const generatePublicUploadFormData = async (file: File, agencyName: string): Promise<FormData> => {
  const formData = new FormData();
  formData.append('upload_preset', public_official_img_preset);
  const timestamp = String(Math.floor(Date.now() / 1000));
  formData.append('timestamp', timestamp);
  formData.append('folder', `public_registration/${agencyName}/official_images`);
  formData.append('file', file);

  return formData;
};

export const uploadOfficialImage = async (
  file: File,
  accessToken: string,
  agencyId: number
): Promise<{ data?: PartialCloudinaryImageAsset; error?: any }> => {
  const options: AxiosRequestConfig = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  };

  try {
    const formData = await generateUploadFormData(file, accessToken, agencyId);
    const response = await axios.post(
      `${cloudinaryAPIBaseUrl}/${cloudinary_cloud_name}/image/upload`,
      formData,
      options
    );

    return { data: response.data };
  } catch (error) {
    console.error(error);
    return { error: 'Failed to upload image.' };
  }
};

export const uploadPublicOfficialImage = async (
  file: File,
  agencyName: string
): Promise<{ data?: PartialCloudinaryImageAsset; error?: any }> => {
  const options: AxiosRequestConfig = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  };

  try {
    const formData = await generatePublicUploadFormData(file, agencyName);
    const response = await axios.post(
      `${cloudinaryAPIBaseUrl}/${cloudinary_cloud_name}/image/upload`,
      formData,
      options
    );

    return { data: response.data };
  } catch (error) {
    console.error(error);
    return { error: 'Failed to upload image.' };
  }
};
