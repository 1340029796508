import { Button, CircularProgress, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface DownloadButtonProps {
  disabled: boolean;
  loading: boolean;
  download: () => void;
  children: ReactNode;
}

const DownloadButton = ({ disabled, loading, download, children }: DownloadButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      type="submit"
      color="primary"
      variant="contained"
      disableElevation
      disabled={disabled}
      onClick={download}
      sx={{
        borderRadius: 2,
        minWidth: '7rem',
        minHeight: '2.25rem',
        color: theme.palette.text.primary
      }}
    >
      {loading ? (
        <CircularProgress size="1rem" sx={{ color: theme.palette.text.primary }} />
      ) : (
        children
      )}
    </Button>
  );
};

export default DownloadButton;
