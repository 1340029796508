import surePassIcon from '../../assets/surePassIcon.svg';
import { Box } from '@mui/material';
import './pageLoader.css';

export const PageLoader: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999
      }}
    >
      <img
        className="loading-item"
        alt="spinning surepass icon loading indicator"
        src={surePassIcon}
      />
    </Box>
  );
};
