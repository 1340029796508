import { UserState } from '../../../hooks/useUser';
import { Location, fetchAgencyLocations } from '../../../networkRequest/locationsService';
import { byId } from '../../../utils/misc';

export type LocationData = {
  locations: Location[];
  locationsById: Record<number, Location>;
};

export const getLocationData = async (user: UserState): Promise<LocationData> => {
  const { data, error } = await fetchAgencyLocations(user.accessToken, user.profile.agency.id);

  if (error) {
    throw new Error(error);
  } else if (!data) {
    throw new Error('No data returned');
  }
  return {
    locations: data,
    locationsById: byId(data)
  };
};
