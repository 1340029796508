import { Button, CircularProgress, DialogActions, useTheme } from '@mui/material';

interface AddAgencyOfficialDialogActionsProps {
  onClose: () => void;
  padding: string;
  canContinue: boolean;
  loading: boolean;
}

const AddAgencyOfficialDialogActions = ({
  onClose,
  padding,
  canContinue,
  loading
}: AddAgencyOfficialDialogActionsProps) => {
  const theme = useTheme();

  return (
    <DialogActions sx={{ padding }}>
      <Button onClick={onClose} variant="outlined" color="secondary" sx={{ borderRadius: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        disableElevation
        disabled={!canContinue}
        // dictated height and width so that the loader doesn't make the button appear different
        // when MUI moves the loadingButton component from the lab to the actual library, probably use that instead.
        sx={{ borderRadius: 2, minWidth: '7rem', minHeight: '2.25rem' }}
      >
        {loading ? (
          <CircularProgress size="1rem" sx={{ color: theme.palette.text.primary }} />
        ) : (
          'Continue'
        )}
      </Button>
    </DialogActions>
  );
};

export default AddAgencyOfficialDialogActions;
