export const validateOfficialImage = (file) => {
  // file size less than 3mb
  if (file.size > 3 * 1024 * 1024) {
    return {
      code: 'size-too-large',
      message: 'File too large'
    };
  }

  // there's an 'accepts' prop alternative that may be more trustworthy but we can't customize the error messages
  if (!['image/jpeg', 'image/jpg', 'image/png', 'image/heic'].includes(file.type)) {
    return {
      code: 'file-invalid-type',
      message: 'Unsuported file'
    };
  }
  return null;
};