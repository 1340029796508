import React, { useState } from 'react';
import AddAgencyOfficialDialogContent from './AddAgencyOfficialDialogContent';
import { useUser } from '../../../../../hooks/useUser';
import {
  addAgencyOfficial,
  EmploymentType,
  OfficialData
} from '../../../../../networkRequest/officialsService';
import AddAgencyOfficialDialogActions from '../AddAgencyOfficialDialogActions';
import { z as zod } from 'zod';
import { SelectChangeEvent } from '@mui/material';
import { Agency } from '../../../../../networkRequest/agenciesService';
import { AgencyOfficialTypesMap, AgencyOfficialRolesMap } from '../../agencyOfficialsDataTable';
import { createDynamicStringValidator } from '../../../../../utils/validationTools';

interface AddAgencyOfficialDialogFormProps {
  onClose: () => void;
  onError: (error: string) => void;
  types: AgencyOfficialTypesMap;
  typesByName: AgencyOfficialRolesMap;
  agencies: Agency[];
  roles: AgencyOfficialRolesMap;
  padding: string;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setNewOfficial: React.Dispatch<React.SetStateAction<OfficialData | null>>;
}

export interface FormOfficialData {
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  role: string;
  rank: string;
  agency_official_type_id: number | null;
  armed_permission: boolean;
  employment_type: EmploymentType;
  agency: string;
}

export const formOfficialDataInit: FormOfficialData = {
  first_name: '',
  last_name: '',
  title: '',
  email: '',
  role: 'Official',
  rank: '',
  armed_permission: false,
  employment_type: 'full_time',
  agency: '',
  agency_official_type_id: null
};

export interface FormattedFormOfficialData {
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  role_id: number;
  rank: string;
  agency_official_type_id: number | null;
  armed_permission: boolean;
  employment_type: string;
  agency: number;
}

const AddAgencyOfficialDialogForm = ({
  onClose,
  onError,
  types,
  typesByName,
  agencies,
  roles,
  padding,
  setActiveStep,
  setNewOfficial
}: AddAgencyOfficialDialogFormProps) => {
  const user = useUser();
  formOfficialDataInit.agency = user?.profile.agency.name || '';

  const [formOfficialData, setFormOfficialData] = useState(formOfficialDataInit);
  const [errors, setErrors] = useState<zod.ZodError<any> | null>(null);
  const [loading, setLoading] = useState(false);

  const roleNames = Array.from(roles.keys()) as string[];
  const zRoleEnum = createDynamicStringValidator(roleNames);
  const agencyNames = agencies.map((agency) => agency.name);
  const zAgencyEnum = createDynamicStringValidator(agencyNames);

  // inside of component so we have access to props to confirm values of dropdowns.
  const officialValidationSchema = zod.object({
    first_name: zod.string().min(1, 'First Name is required.'),
    last_name: zod.string().min(1, 'Last name is required.'),
    title: zod.string().min(1, 'Title is required.'),
    email: zod.string().email(),
    role: zRoleEnum,
    rank: zod.string().optional(),
    type: zod.string().optional(),
    armed_permission: zod.boolean(),
    employment_type: zod.string().min(1),
    agency: zAgencyEnum
  });

  const formatData = (formOfficialData: FormOfficialData) => {
    const matchedAgency = agencies.find((agency) => agency.name === formOfficialData.agency)?.id;
    const matchedRole = roles.get(formOfficialData.role);

    if (matchedRole && matchedAgency) {
      const data: FormattedFormOfficialData = {
        first_name: formOfficialData.first_name,
        last_name: formOfficialData.last_name,
        title: formOfficialData.title,
        email: formOfficialData.email,
        role_id: matchedRole,
        rank: formOfficialData.rank,
        agency_official_type_id: formOfficialData.agency_official_type_id || null,
        armed_permission: formOfficialData.armed_permission,
        employment_type: formOfficialData.employment_type,
        agency: matchedAgency || user!.profile.agency.id
      };

      return data;
    }
  };

  const validateForm = () => {
    try {
      officialValidationSchema.parse(formOfficialData);
      setErrors(null);
      return true;
    } catch (formErrors) {
      if (formErrors instanceof zod.ZodError) {
        setErrors(formErrors);
      } else {
        onError('Error creating official.');
      }
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (!user) {
      throw new Error('User not found');
    }
    const data = formatData(formOfficialData);
    const validForm = validateForm();

    if (!validForm) {
      setLoading(false);
      return;
    }
    if (!data) return;

    try {
      const response = await addAgencyOfficial(user.accessToken, data);
      if (response.error) throw new Error(response.error);
      if (response && response.data) {
        setActiveStep(1);
        setNewOfficial(response.data);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // must have separate handlers or a type guard to appease TS
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormOfficialData({ ...formOfficialData, [name]: value });
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    if (name === 'agency_official_type_id') {
      setFormOfficialData({ ...formOfficialData, [name]: typesByName.get(value) || null });
    } else {
      setFormOfficialData({ ...formOfficialData, [name]: value });
    }
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    setFormOfficialData({ ...formOfficialData, [name]: checked });
  };

  return (
    <>
      <form onSubmit={handleSubmit} role="form">
        <AddAgencyOfficialDialogContent
          handleChange={{ handleInputChange, handleSelectChange, handleSwitchChange }}
          setFormOfficialData={setFormOfficialData}
          formOfficialData={formOfficialData}
          types={types}
          agencies={agencies}
          roles={roles}
          formErrors={errors}
        />
        <AddAgencyOfficialDialogActions
          onClose={onClose}
          padding={padding}
          canContinue={true}
          loading={loading}
        />
      </form>
    </>
  );
};

export default AddAgencyOfficialDialogForm;
