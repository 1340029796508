import { Box, Container } from '@mui/material';
import Checkpoints from '../features/checkpoints/components/Checkpoints';

export const CheckpointsPage = () => {
  return (
    <Box component={'main'} gridArea={'main'}>
      <Container sx={{ height: '100%' }}>
        <Checkpoints />
      </Container>
    </Box>
  );
};

export default CheckpointsPage;
