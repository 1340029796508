import { ReactElement, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CheckpointsPage } from './pages/checkpointsPage';
import LandingPage from './pages/landingPage';
import { LocationsPage } from './pages/locationsPage';
import { ManageOfficialsPage } from './pages/manageOfficialsPage';
import { UserDetailsPage } from './pages/userDetailsPage';
import { PublicRegistrationPage } from './pages/publicRegistrationPage';
import PageLayout from './containers/pageLayout';
import { PageLoader } from './components/PageLoader/PageLoader';
import './App.css';
import AccessLogsPage from './pages/accessLogsPage';
import { useAuthContext } from './auth/auth0-provider-with-navigate';
import { useUser } from './hooks/useUser';
import { createAuthAction } from './networkRequest/officialsService';
import NotFound from './pages/notFoundPage';

interface Auth0Error extends Error {
  error: string;
}

const App = (): ReactElement => {
  const { isLoading, error, isAuthenticated, loginWithRedirect } = useAuth0();
  const [silentAuthAttempted, setSilentAuthAttempted] = useState(false);
  const user = useUser();
  const { loginNeedsRecording, setLoginNeedsRecording } = useAuthContext();
  const isSuperAdmin = user?.profile?.role?.name === 'Super Admin' || false;
  const isActive = user?.profile?.status === 'active';

  const location = useLocation();
  const currentPathname = location.pathname;

  useEffect(() => {
    const recordLogin = async () => {
      if (user) {
        const { data, error } = await createAuthAction(user.accessToken, user.profile.id, 'login');
        if (data) {
          setLoginNeedsRecording(false);
        } else if (error) {
          console.error(error);
        }
      }
    };
    if (user && loginNeedsRecording) {
      recordLogin();
    }
  }, [user, loginNeedsRecording, setLoginNeedsRecording]);

  // check if the user is at /register before even bothering with any authentication items since it's a public route
  // any other future public routes can go here, too.
  // (does have to be after the useEffect to avoid making the useEffect technically conditional)
  if (currentPathname === '/register') {
    return (
      <Routes>
        <Route path="/register" element={<PublicRegistrationPage />} />
      </Routes>
    );
  }

  if (isLoading) {
    return <PageLoader />;
  }

  // Attempt silent authentication first
  if (!isAuthenticated && !silentAuthAttempted) {
    setSilentAuthAttempted(true);

    loginWithRedirect({
      authorizationParams: {
        prompt: 'none' // Attempt silent login
      },
      appState: {
        silentAttempt: true,
        returnTo: window.location.pathname
      }
    });
  }

  if (error) {
    // If silent auth fails it will return this error message
    // This indicates that the user needs to login manually
    const auth0Error = error as Auth0Error;
    if (auth0Error.error && auth0Error.error.includes('login_required')) {
      loginWithRedirect({
        appState: {
          silentAttempt: false,
          returnTo: window.location.pathname
        }
      });
    } else {
      return <div>Oops... {error.message}</div>;
    }
  }

  if (!isAuthenticated || !user) {
    return <PageLoader />;
  }

  if (isSuperAdmin && isActive) {
    return (
      <Routes>
        <Route element={<PageLayout />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/manage-officials" element={<ManageOfficialsPage />} />
          <Route path="/manage-officials/:officialId" element={<UserDetailsPage />} />
          <Route path="/locations" element={<LocationsPage />} />
          <Route path="/checkpoints" element={<CheckpointsPage />} />
          <Route path="/access-logs" element={<AccessLogsPage />} />
        </Route>
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }
};

export default App;
