import { FormHelperText, Grid2 as Grid, TextField, useMediaQuery, useTheme } from '@mui/material';
import { CustomErrorMap, NewOfficialData } from './PublicRegistrationForm';

interface PublicRegistrationFormInputsProps {
  newOfficialData: NewOfficialData;
  formErrors: CustomErrorMap;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PublicRegistrationFormInputs = ({
  newOfficialData,
  formErrors,
  handleInputChange
}: PublicRegistrationFormInputsProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const inputs = [
    {
      name: 'first_name',
      label: 'First Name (Required)',
      placeholder: 'First Name',
      value: newOfficialData.first_name,
      required: true
    },
    {
      name: 'last_name',
      label: 'Last Name (Required)',
      placeholder: 'Last Name',
      value: newOfficialData.last_name,
      required: true
    },
    {
      name: 'title',
      label: 'Title (Required)',
      placeholder: 'Overnight Facility Security Agent',
      value: newOfficialData.title,
      required: true
    },
    {
      name: 'rank',
      label: 'Rank (Required)',
      placeholder: 'Police Sergeant',
      value: newOfficialData.rank,
      required: true
    },
    {
      name: 'badge_number',
      label: 'Badge (Required)',
      placeholder: '1528',
      value: newOfficialData.badge_number,
      required: true
    }
  ];

  return (
    <>
      {inputs.map((input) => {
        const hasFormError = formErrors[input.name]?.length > 0;
        return (
          <Grid size={isSmallScreen ? 12 : 6} key={input.name}>
            <TextField
              autoFocus
              error={hasFormError}
              label={input.label}
              sx={{
                '& .MuiInputLabel-outlined.Mui-focused': {
                  color: theme.palette.primary.dark
                }
              }}
              name={input.name}
              onChange={handleInputChange}
              placeholder={input.placeholder}
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              value={input.value}
              fullWidth
            />
            <FormHelperText
              error={hasFormError}
              sx={{ fontSize: '10pt', color: theme.palette.error.main }}
            >
              {formErrors[input.name]}
            </FormHelperText>
          </Grid>
        );
      })}
    </>
  );
};

export default PublicRegistrationFormInputs;
