import styled from '@mui/system/styled';
import { Button, ButtonProps } from '@mui/material';

const StyledSmallButton = styled(Button)({
  display: 'inline-block',
  fontSize: 14,
  lineHeight: '20px',
  textWrap: 'nowrap',
  height: 28,
  borderRadius: 8,
  fontWeight: 500
});

export const SmallButton = (props: ButtonProps) => (
  <StyledSmallButton variant="outlined" size="small" color="secondary" {...props} />
);

export default SmallButton;
