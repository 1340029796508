import { OfficialData } from '../../../networkRequest/officialsService';

export function flattenOfficials(officials: OfficialData[]) {
  const flattenedOfficials = officials.map((official) => {

    return {
      id: official.id,
      first_name: official.first_name,
      last_name: official.last_name,
      employment_type: official.employment_type,
      official_id: official.official_id,
      agency_id: official.agency_id,
      invite_status: official.invite_status,
      title: official.title,
      email: official.email,
      img_url: official.img_url,
      agency_official_type: official.agency_official_type,
      agency_official_type_id: official.agency_official_type_id,
      rank: official.rank,
      role_name: official.role.name,
      role_id: official.role.id,
      verified: official.verified,
      badge_number: official.badge_number,
      armed_permission: official.armed_permission,
      status: official.status,
      groups: official.groups
    };
  });
  return flattenedOfficials;
}
