import { UserState } from '../../../hooks/useUser';
import { AgencyStats, fetchAgencyStats } from '../../../networkRequest/agencyStatsService';

export const getData = async (user: UserState): Promise<AgencyStats> => {
  const { data, error } = await fetchAgencyStats(user.accessToken, user.profile.agency.id);
  if (error) {
    throw new Error(error);
  } else if (!data) {
    throw new Error('No data returned');
  }

   return data;
};
