import Toast from '../../../components/toast/Toast';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { fetchCheckpointAccesses } from '../../../networkRequest/checkpointAccessesService';
import { Location, fetchAgencyLocations } from '../../../networkRequest/locationsService';
import { useUser } from '../../../hooks/useUser';
import LocationPicker from './LocationPicker';
import OfficialAvatar from '../../../components/officialAvatar';
import formatDate from '../../../utils/formatDate';
import { Box, Grid2 as Grid, Typography } from '@mui/material';
import DownloadButton from './DownloadButton';
import { DownloadIcon } from '../../../assets/icons/icons';
import { removeSpecifiedDataPoints, download, formatData } from '../utils/csvHelpers';

export interface FlatCheckpointAccess {
  id: number;
  profile_img: string;
  checkpoint_name: string;
  first_name: string;
  last_name: string;
  performed_at: string;
  armed: boolean;
  armed_permission: boolean;
  photo_matches: boolean;
  device_id: string;
  verifier: string;
}

export default function AccessLogsDataTable() {
  const [locations, setLocations] = useState<Location[]>([]);
  const [locationSelected, setLocationSelected] = useState<number | null>(null);
  const [rows, setRows] = useState<FlatCheckpointAccess[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const user = useUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user) return;
        const { data: responseData, error } = await fetchAgencyLocations(
          user.accessToken,
          user.profile.agency.id
        );

        if (error) {
          throw new Error(error);
        } else if (responseData) {
          setLocations(responseData);
        }
      } catch (e: any) {
        setError(e.message);
      }
    };

    fetchData();
  }, [user]);

  const accessLogsColumns = [
    { field: 'checkpoint_name', headerName: 'Checkpoint Name', width: 150 },
    {
      field: 'first_name',
      headerName: 'First Name',
      renderCell: (params) => (
        <OfficialAvatar
          img_url={params.row.profile_img}
          name={params.row.first_name}
          square={true}
        ></OfficialAvatar>
      ),
      width: 150
    },
    { field: 'last_name', headerName: 'Last Name', width: 125 },
    { field: 'action', headerName: 'Action', width: 150 },
    { field: 'verifier', headerName: 'Verifier', width: 150 },
    { field: 'performed_at_date', headerName: 'Date', width: 150 },
    { field: 'performed_at_time', headerName: 'Time', width: 150 },
    { field: 'armed', headerName: 'Armed', width: 75 },
    { field: 'armed_permission', headerName: 'Armed Permission', width: 150 },
    { field: 'photo_matches', headerName: 'Photo Matches?', width: 150 },
    { field: 'device_id', headerName: 'Device ID', width: 100 }
  ];

  const actionTypeDisplayName = (action_type: string): string => {
    switch (action_type) {
      case 'cleared':
        return 'Cleared For Entry';
      case 'uncleared':
        return 'Not Cleared For Entry';
      case 'id_scan':
        return 'ID Scan';
      default:
        return 'Unknown'; // This should never be used but TS requires it
    }
  };

  const flattenCheckpointAccesses = (data): FlatCheckpointAccess[] => {
    return data.map((checkpointAccess) => ({
      id: checkpointAccess.id,
      profile_img: checkpointAccess.agency_official.img_url,
      checkpoint_id: checkpointAccess.checkpoint.id,
      checkpoint_name: checkpointAccess.checkpoint.name,
      agency_official_id: checkpointAccess.agency_official.id,
      first_name: checkpointAccess.agency_official.first_name,
      last_name: checkpointAccess.agency_official.last_name,
      action: actionTypeDisplayName(checkpointAccess.action_type),
      performed_at_date: formatDate(checkpointAccess.performed_at).date,
      performed_at_time: formatDate(checkpointAccess.performed_at).time,
      armed: checkpointAccess.armed,
      armed_permission: checkpointAccess.armed_permission,
      photo_matches: checkpointAccess.photo_matches,
      device_id: checkpointAccess.device_id,
      verifier: `${checkpointAccess.verifier.first_name} ${checkpointAccess.verifier.last_name}`,
      verifier_id: checkpointAccess.verifier.id,
      verifier_first_name: checkpointAccess.verifier.first_name,
      verifier_last_name: checkpointAccess.verifier.last_name
    }));
  };

  const onLocationSelect = async (selectedLocationId: number): Promise<void> => {
    if (!user) {
      return;
    }
    setLocationSelected(selectedLocationId);
    setLoading(true);
    const { data, error } = await fetchCheckpointAccesses(user.accessToken, selectedLocationId);
    if (data) {
      setRows(flattenCheckpointAccesses(data));
      setLoading(false);
    } else if (error) {
      setError(error);
    }
  };

  const downloadAccessLogs = () => {
    setDownloadLoading(true);

    try {
      if (rows.length === 0) throw new Error('No access logs for this location.');
      const currentDateTime = new Date().toISOString();
      const cleanData = removeSpecifiedDataPoints(rows, ['verifier']);
      const formattedData = formatData(cleanData);
      const currentLocationName = locations.find(
        (location) => location.id === locationSelected
      )!.name;
      download(
        formattedData,
        `${currentLocationName}_${rows[0].checkpoint_name}_${currentDateTime}`
      );
    } catch (error: any) {
      setError(error.message);
    }
    setDownloadLoading(false);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Grid
          container
          sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}
        >
          <LocationPicker locations={locations} onSelect={onLocationSelect} />
          <Grid>
            <DownloadButton
              disabled={rows.length === 0}
              loading={downloadLoading}
              download={downloadAccessLogs}
            >
              <DownloadIcon
                color={rows.length === 0 ? 'inherit' : 'secondary'}
                sx={{ fontSize: 18, marginRight: 1 }}
              />
              Download Logs (CSV)
            </DownloadButton>
          </Grid>
        </Grid>
        <Box flexGrow={1}>
          <DataGrid
            columns={accessLogsColumns}
            rows={rows}
            loading={loading}
            disableRowSelectionOnClick
            slots={{
              noRowsOverlay: () => (
                <Typography variant="h6" align="center">
                  {locationSelected
                    ? 'No Access Logs for this Location'
                    : 'Select a Location to view Access Logs'}
                </Typography>
              )
            }}
            slotProps={{
              loadingOverlay: {
                variant: 'skeleton'
              }
            }}
            sx={{
              '--DataGrid-overlayHeight': '300px',
              height: '100%',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: '600'
              },
              '& .MuiDataGrid-toolbarContainer': {
                backgroundColor: '#E3F2FD'
              },
              '& .MuiDataGrid-columnHeaderTitle, & .MuiButton-text, & [data-testid="IndeterminateCheckBoxIcon"], & [data-testid="CheckBoxIcon"], & [data-testid="CheckBoxOutlineBlankIcon"]':
                {
                  color: 'navyBlue'
                },
              '& .MuiDataGrid-overlayWrapperInner': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
              }
            }}
          />
        </Box>
      </Box>

      {error && (
        <Toast type="error" onClose={() => setError(null)}>
          {error}
        </Toast>
      )}
    </>
  );
}
