import { FormattedFormOfficialData } from '../features/agencyOfficials/components/AddAgencyOfficialDialog/Step 1/AddAgencyOfficialDialogForm';
import { uploadOfficialImage } from './cloudinaryService';
import { getReq, putReq, postReq } from './api';
import { UserProfile } from '../hooks/useUser';
import { getDeviceInfo } from '../utils/deviceInfo';
import { NewOfficialWithToken } from '../features/publicRegistration/PublicRegistrationForm';

export type EmploymentType = 'law_enforcement' | 'full_time' | 'part_time' | 'seasonal';

export const employmentTypeOptions = [
  { value: 'law_enforcement', label: 'Law Enforcement' },
  { value: 'full_time', label: 'Full Time' },
  { value: 'part_time', label: 'Part Time' },
  { value: 'seasonal', label: 'Seasonal' }
];

export interface Official {
  id: number;
  first_name: string;
  last_name: string;
}

export interface Agency {
  id: number;
  name: string;
  logo_url: string | null;
}

export interface OfficialCredential {
  id: number;
  official_credential_id: string;
  client_provided: boolean;
  status: string;
  created_at: string;
  updated_at: string;
  agency_official_id: number;
  credential_type: string;
}

export interface CredentialInput {
  credential_type: string;
  status?: string;
}

export interface CredentialInput {
  credential_type: string;
  status?: string;
}

export interface AgencyGroup {
  id: number;
  agency_id: number;
  default: boolean;
  name: string;
}

interface AgencyOfficialRole {
  id: number;
  name: string;
}

interface AgencyOfficialType {
  name: string;
  id: number;
}

interface AuthAction {
  id: number;
  agency_official_id: number;
  device_id: string;
  auth_action: string;
  created_at: string;
  updated_at: string;
}

export interface OfficialData {
  id: number;
  first_name: string;
  last_name: string;
  official_id: number;
  agency_id: number;
  invite_status: string;
  title: string;
  email: string;
  agency_official_type: AgencyOfficialType | null;
  img_url?: string;
  agency_official_type_id: number | null;
  employment_type: EmploymentType;
  rank: string;
  role: AgencyOfficialRole;
  verified: boolean;
  badge_number: string;
  armed_permission: boolean;
  status: string;
  official: Official;
  agency: Agency;
  groups: AgencyGroup[] | [];
  official_credentials: OfficialCredential[];
}

// lots of properties we probably won't need, so I'm just including a few and we can always add more later.
export interface PartialCloudinaryImageAsset {
  existing: boolean;
  url: string;
  bytes: number;
  access_mode: string;
  public_id: string;
}

export const fetchOfficials = async (
  accessToken: string,
  agencyId: number
): Promise<{ data?: OfficialData[]; error?: any }> => {
  return getReq<OfficialData[]>(
    `/agencies/${agencyId}/agency_officials`,
    accessToken,
    'Failed to fetch officials.'
  );
};

export const getOfficial = async (
  accessToken: string,
  officialId: number
): Promise<{ data?: OfficialData; error?: any }> => {
  return getReq<OfficialData>(
    `/agency_officials/${officialId}`,
    accessToken,
    'Failed to fetch official.'
  );
};

export const findOfficial = async (
  accessToken: string,
  officialEmail: string
): Promise<{ data?: UserProfile[]; error?: any }> => {
  const encodedEmail = encodeURIComponent(officialEmail);

  return getReq<UserProfile[]>(
    `/agency_officials?email=${encodedEmail}`,
    accessToken,
    'Failed to find official.'
  );
};

export const updateOfficial = async (
  accessToken: string,
  officialUpdates: Partial<OfficialData>
): Promise<{ data?: OfficialData; error?: string }> => {
  return putReq<OfficialData>(
    `/agency_officials/${officialUpdates.id}`,
    accessToken,
    officialUpdates,
    'Failed to update official.'
  );
};

export const addAgencyOfficial = async (
  accessToken: string,
  data: FormattedFormOfficialData
): Promise<{ data?: OfficialData; error?: string }> => {
  return postReq<OfficialData>(
    `/agencies/${data.agency}/agency_officials`,
    accessToken,
    data,
    'Failed to update official.'
  );
};

export const publicAddAgencyOfficial = async (
  data: NewOfficialWithToken
): Promise<{ error?: string; status?: number }> => {
  return postReq<{ status: number }>(
    `/registrations`,
    undefined,
    data,
    'Failed to register official.'
  );
};

export const addCredential = async (
  accessToken: string,
  officialId: number,
  credential: CredentialInput
): Promise<{ data?: OfficialCredential; error?: string }> => {
  return postReq<OfficialCredential>(
    `/agency_officials/${officialId}/credentials`,
    accessToken,
    credential,
    'Failed to add credential.'
  );
};

export const updateCredential = async (
  accessToken: string,
  credential: Partial<OfficialCredential> & {
    official_credential_id: OfficialCredential['official_credential_id'];
  }
): Promise<{ data?: OfficialCredential; error?: string }> => {
  return putReq<OfficialCredential>(
    `/credentials/${credential.official_credential_id}`,
    accessToken,
    credential,
    'Failed to update credential.'
  );
};

export const deleteCredential = async (
  accessToken: string,
  credentialId: string
): Promise<{ data?: { message: string }; error?: string }> => {
  const { data, error } = await updateCredential(accessToken, {
    official_credential_id: credentialId,
    status: 'deactivated'
  });
  if (error) {
    return { error };
  }
  if (!data) {
    return { error: 'Failed to delete credential.' };
  }
  return { data: { message: 'Credential deleted.' } };
};

export const updateOfficialImage = async (
  accessToken: string,
  officialId: number,
  agencyId: number,
  imageFile: File
): Promise<{ data?: OfficialData; error?: any }> => {
  const { data: cloudinaryData, error } = await uploadOfficialImage(
    imageFile,
    accessToken,
    agencyId
  );
  if (cloudinaryData) {
    const { data, error } = await updateOfficial(accessToken, {
      id: officialId,
      img_url: cloudinaryData.secure_url
    });
    if (data) {
      return { data };
    } else if (error) {
      return { error };
    }
  } else if (error) {
    return { error: error };
  }

  return { error: 'Failed to update official image' };
};

export const sendEmailInvites = async (
  accessToken: string,
  ids: number[]
): Promise<{ data?: number[]; error?: string }> => {
  return postReq<number[]>(
    `/invites`,
    accessToken,
    { agency_officials: ids },
    'Failed to send email invites.'
  );
};

export const createAuthAction = async (
  accessToken: string,
  officialId: number,
  auth_action: string
): Promise<{ data?: AuthAction; error?: string }> => {
  return postReq<AuthAction>(
    `/agency_officials/${officialId}/auth_actions`,
    accessToken,
    { device_id: getDeviceInfo(), auth_action: auth_action },
    'Failed to create auth action.'
  );
};
