import AgencyLocationsTable from '../features/agencyLocations/components/AgencyLocationsTable';
import { Box, Container, Divider, Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

export const LocationsPage: React.FC = () => {
  const theme: Theme = useTheme();
  return (
    <Box component={'main'} gridArea={'main'}>
      <Container sx={{ height: '100%' }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2, mb: 2 }}
          color={theme.palette.text.primary}
        >
          <Typography variant="h4">Locations</Typography>
          <Divider />
        </Box>
        <AgencyLocationsTable />
      </Container>
    </Box>
  );
};

export default LocationsPage;
