import { Avatar, Card, CardActions, CardContent, Grid2 as Grid, useTheme } from '@mui/material';
import ArrowButtonLink from '../ArrowButtonlink';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface LinkBlockProps {
  title: string;
  link: string;
  background: string;
  children: ReactNode;
}

const LinkBlock = ({ title, link, background, children }: LinkBlockProps) => {
  const theme = useTheme();

  return (
    <Link to={link} style={{ width: '100%', flex: 1, textDecoration: 'none' }} data-testid="link">
      <Card
        sx={{
          backgroundColor: theme.palette.powderBlue,
          borderRadius: 2,
          height: '28vh',
          boxShadow: 'none',
          padding: 2,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <CardContent sx={{ padding: 1 }}>
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ backgroundColor: theme.palette.secondary.main, width: 62, height: 62 }}>
              <Avatar
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  border: `2px solid ${theme.palette.primary.main}`,
                  width: 56,
                  height: 56
                }}
              >
                {children}
              </Avatar>
            </Avatar>
            <p style={{ fontSize: '16pt', lineHeight: 1, paddingLeft: '1rem' }}>{title}</p>
          </Grid>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ArrowButtonLink />
        </CardActions>
      </Card>
    </Link>
  );
};

export default LinkBlock;
