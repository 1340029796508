const year : number = new Date().getFullYear();

const copy = {
  menuTitle: {
    home: 'Home',
    manageOfficials: 'Manage Officials',
    locations: 'Locations',
    checkpoints: 'Checkpoints',
    accessLogs: 'Access Logs',
    agencyInfo: 'Agency Info',
    help: 'Help'
  },
  copyrightFooter: `© ${year}, SurePass ID Inc.`,
  publicRegistration: {
    success: 'Congratulations! Your SurePass account has been successfully created. Please check your inbox for further instructions. Note that additional security checks may be required.',
    failure: 'Sorry, we are unable to verify your credentials. Please contact support for assistance at support@surepass.com'
  },
  imageUpload: {
    fileType: 'File must be PNG, JPG, or HEIC (max. 3MB)',
    step1: 'You must be the only person in the photo.',
    step2: 'Include and center your full head and shoulders only.',
    step3: 'Your face must be clearly visible and facing the camera.',
  }
};

export default copy;
