import {
  Autocomplete,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';

interface PublicRegistrationFormAutocompleteProps {
  agencies: string[];
  handleSelectChange: (_: React.SyntheticEvent, value: string | null) => void;
  currentAgency: string;
}

const PublicRegistrationFormAutocomplete = ({
  agencies,
  handleSelectChange,
  currentAgency
}: PublicRegistrationFormAutocompleteProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid size={isSmallScreen ? 12 : 6}>
      <FormControl fullWidth variant="outlined" required>
        <InputLabel
          htmlFor="agency"
          shrink
          sx={{
            backgroundColor: theme.palette.grey[50]
          }}
        >
          Law Enforcement Agency (Required)
        </InputLabel>
        <Autocomplete
          disablePortal
          id="agency"
          value={currentAgency}
          options={agencies}
          onChange={handleSelectChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              variant="outlined"
              required
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
            />
          )}
        />
      </FormControl>
    </Grid>
  );
};

export default PublicRegistrationFormAutocomplete;
