import { UserState } from '../../../hooks/useUser';
import { Agency, getAgencies, getAgency } from '../../../networkRequest/agenciesService';
import { fetchOfficials, OfficialData } from '../../../networkRequest/officialsService';

export const getOfficialsData = async (user: UserState): Promise<OfficialData[]> => {
  const { data, error } = await fetchOfficials(user.accessToken, user.profile.agency.id);

  if (error) {
    throw new Error(error);
  } else if (!data) {
    throw new Error('No data returned');
  }

  return data;
};

export const getAgencyData = async (user: UserState): Promise<Agency> => {
  const { data, error } = await getAgency(user.accessToken, user.profile.agency.id);

  if (error) {
    throw new Error(error);
  } else if (!data) {
    throw new Error('No data returned');
  }

  return data;
};

export const getAgenciesData = async (user: UserState): Promise<Agency[]> => {
  const { data, error } = await getAgencies(user.accessToken);

  if (error) {
    throw new Error(error);
  } else if (!data) {
    throw new Error('No data returned');
  }

  return data;
};
