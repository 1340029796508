import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid2 as Grid,
  useTheme,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from '@mui/material';
import {
  updateCredential,
  CredentialInput,
  OfficialCredential
} from '../../../networkRequest/officialsService';
import { useUser } from '../../../hooks/useUser';

type CredentialDialogProps = {
  credential: OfficialCredential;
  open: boolean;
  onClose: () => void;
  onSubmit?: (formData: CredentialInput) => void;
  onError: (error: string) => void;
};

export const EditCredentialDialog = ({
  credential,
  open,
  onClose = () => {},
  onSubmit = () => {},
  onError
}: CredentialDialogProps) => {
  const user = useUser();
  const theme = useTheme();
  const padding = theme.spacing(4);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!user) {
      throw new Error('User not found');
    }
    const status = (e.currentTarget.elements.namedItem('status') as HTMLSelectElement)?.value;
    const response = await updateCredential(user.accessToken, {
      official_credential_id: credential.official_credential_id,
      status
    });
    if (response.error) {
      onError(response.error);
    } else if (response.data) {
      onSubmit?.(response.data);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit} role="form">
        <DialogTitle sx={{ padding }}>Edit Credential</DialogTitle>
        <DialogContent sx={{ padding }}>
          <Grid container spacing={3} padding={1}>
            <Grid size={12}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  label="Credential Type"
                  defaultValue={credential.status}
                  fullWidth
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="revoked">Revoked</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding, pt: 0, mr: 1 }}>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditCredentialDialog;
