import { useState } from 'react';
import {
  TextField,
  Chip,
  Autocomplete,
  Avatar,
  Box,
  Typography,
  TextFieldProps
} from '@mui/material';
import { OfficialData } from '../../../networkRequest/officialsService';

type EmailInviteChipProps = TextFieldProps & {
  availableRecipients: OfficialData[];
  initRecipients: OfficialData[];
  onRecipientsChange: (recipients: OfficialData[]) => void;
};

const getLabel = (opt: OfficialData) => `${opt.first_name} ${opt.last_name}`;

export const EmailInviteChips = ({
  availableRecipients,
  initRecipients,
  onRecipientsChange,
  ...props
}: EmailInviteChipProps) => {
  const [recipients, setRecipients] = useState<OfficialData[]>(initRecipients);

  const handleChange = (_event: unknown, selectedOptions: OfficialData[]) => {
    setRecipients(selectedOptions);
    onRecipientsChange(selectedOptions);
  };

  return (
    <Autocomplete
      multiple
      disableClearable
      forcePopupIcon={false}
      options={availableRecipients}
      value={recipients}
      getOptionLabel={(option) => getLabel(option)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={handleChange}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option.id}
            label={getLabel(option)}
            icon={<Avatar sx={{ width: 24, height: 24 }} src={option.img_url} />}
            sx={{ backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 0.42)' }}
          />
        ))
      }
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          key={props.key}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
            gap: '8px'
          }}
        >
          <Avatar sx={{ width: 32, height: 32 }} src={option.img_url} />
          <Typography>{getLabel(option)}</Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          label="To"
          placeholder={recipients.length ? '' : 'Select recipients'}
          sx={{ marginTop: '0.5em' }}
        />
      )}
    />
  );
};

export default EmailInviteChips;
