import { getReq, putReq, postReq, deleteReq } from './api';

export interface Location {
  id: number;
  agency_id: number;
  name: string;
  street_address_1: string;
  street_address_2: string;
  city: string;
  state: string;
  postal: string;
}

export const fetchAgencyLocations = async (
  accessToken: string,
  agencyId: number
): Promise<{ data?: Location[]; error?: string }> => {
  return getReq<Location[]>(`/agencies/${agencyId}/locations`, accessToken);
};

export const addAgencyLocation = async (
  accessToken: string,
  agencyId: number,
  location: Location
): Promise<{ data?: Location; error?: string }> => {
  return postReq<Location>(`/agencies/${agencyId}/locations`, accessToken, location);
};

export const updateAgencyLocation = async (
  accessToken: string,
  location: Location
): Promise<{ data?: Location; error?: string }> => {
  return putReq<Location>(`/locations/${location.id}`, accessToken, location);
};

export const deleteAgencyLocation = async (
  accessToken: string,
  locationId: number
): Promise<{ error?: string }> => {
  return deleteReq(`/locations/${locationId}`, accessToken);
};
