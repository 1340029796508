import { EditIcon, RemoveIcon } from '../../../assets/icons/icons';
import { Location } from '../../../networkRequest/locationsService';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import NameCell from './NameCell';

export const createLocationColumns = (
  onEditClick: (id: number) => void,
  onRemoveClick: (id: number) => void
): GridColDef[] => [
  {
    field: 'name',
    headerName: 'Location Name',
    flex: 1,
    renderCell: (params: GridRenderCellParams<Location>) => <NameCell name={params.row.name} />
  },
  { field: 'street_address_1', headerName: 'Street Address', flex: 0.5 },
  { field: 'street_address_2', headerName: 'Address 2', flex: 0.5 },
  { field: 'city', headerName: 'City', flex: 0.5 },
  { field: 'state', headerName: 'State' },
  { field: 'postal', headerName: 'Zip' },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 120,
    renderCell: (params: GridRenderCellParams<Location>) => (
      <>
        <IconButton onClick={() => onEditClick(params.row.id)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => onRemoveClick(params.row.id)}>
          <RemoveIcon />
        </IconButton>
      </>
    )
  }
];
