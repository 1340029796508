import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import styled from '@mui/system/styled';
import InfoIcon from '@mui/icons-material/Info';
import { UploadIcon } from '../../../assets/icons/icons';
import { DropzoneInputProps } from 'react-dropzone';
import copy from '../../../containers/pageLayout/definitions/copy';

const TooltipInfo = styled(Box)({
  '&': {
    padding: 5,
    maxWidth: 175,
    paddingLeft: 15,
    margin: 0
  }
});

interface ImageSelectorProps {
  dropzoneInputProps: DropzoneInputProps;
}

const ImageSelector = ({ dropzoneInputProps }: ImageSelectorProps) => (
  <Box bgcolor={'grey.100'} padding={2} display="flex" justifyContent="space-between">
    <Box>
      <Typography variant="caption" display={'inline-block'} pb={1}>
        SurePass requires a recent photo for in-person identity verification.
      </Typography>
      <Box>
        <input style={{ display: 'none' }} {...dropzoneInputProps} />

        <Button variant="outlined" color="secondary" component="label">
          <UploadIcon sx={{ marginRight: 1, width: 14 }} />
          Upload Photo
        </Button>
      </Box>
    </Box>
    <Box display="flex" alignItems="center">
      <Tooltip
        title={
          <TooltipInfo>
            <ul>
              <li>{copy.imageUpload.step1}</li>
              <li>{copy.imageUpload.step2}</li>
              <li>{copy.imageUpload.step3}</li>
              <li>{copy.imageUpload.fileType}</li>
            </ul>
          </TooltipInfo>
        }
      >
        <IconButton>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </Box>
  </Box>
);

export default ImageSelector;
