import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    brightTeal: React.CSSProperties['color'];
    darkTeal: React.CSSProperties['color'];
    navyBlue: React.CSSProperties['color'];
    skyBlue: React.CSSProperties['color'];
    powderBlue: React.CSSProperties['color'];
    yellow: {
      100: React.CSSProperties['color'];
      200: React.CSSProperties['color'];
      300: React.CSSProperties['color'];
      400: React.CSSProperties['color'];
      500: React.CSSProperties['color'];
      600: React.CSSProperties['color'];
      700: React.CSSProperties['color'];
      800: React.CSSProperties['color'];
      900: React.CSSProperties['color'];
    };
  }

  interface PaletteOptions {
    brightTeal?: React.CSSProperties['color'];

    darkTeal?: React.CSSProperties['color'];

    navyBlue?: React.CSSProperties['color'];

    skyBlue?: React.CSSProperties['color'];

    powderBlue?: React.CSSProperties['color'];

    yellow?: {
      100?: React.CSSProperties['color'];
      200?: React.CSSProperties['color'];
      300?: React.CSSProperties['color'];
      400?: React.CSSProperties['color'];
      500?: React.CSSProperties['color'];
      600?: React.CSSProperties['color'];
      700?: React.CSSProperties['color'];
      800?: React.CSSProperties['color'];
      900?: React.CSSProperties['color'];
    };
  }

  interface PaletteOptions {
    brightTeal?: React.CSSProperties['color'];
    darkTeal?: React.CSSProperties['color'];
    navyBlue?: React.CSSProperties['color'];
    skyBlue?: React.CSSProperties['color'];
    powderBlue?: React.CSSProperties['color'];
  }
}

const verifiTheme = createTheme({
  palette: {
    primary: {
      main: '#08D1FF',
      dark: '#22384B',
      contrastText: '#000',
    },
    secondary: {
      main: '#2A465E'
    },
    text: {
      primary: '#2B455E'
    },
    background: {
      default: '#FFF'
    },
    error: {
      main: '#D32F2F',
      dark: '#C62928',
      light: '#EF5351'
    },
    success: {
      main: '#08D1FF',
    },
    warning: {
      main: '#EF6C00'
    },
    info: {
      main: '#0288D1'
    },
    grey: {
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121'
    },
    yellow: {
      100: '#FFF9C4',
      200: '#FFF59D',
      300: '#FFF176',
      400: '#FFEE58',
      500: '#FFEB3B',
      600: '#FDD835',
      700: '#FBC02D',
      800: '#F9A825',
      900: '#F57F17'
    },
    brightTeal: '#00FAC2',
    darkTeal: '#00B8A8',
    navyBlue: '#2B455E',
    skyBlue: '#00D1FF',
    powderBlue: '#E3F2FD',
  },
  components: {
    MuiInputLabel: {
      defaultProps: {
        shrink: true
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: true
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-containedPrimary': {
            '&:hover, &:focus': {
              backgroundColor: '#66DAFD',
            }
          },
        },
      },
    },
  },
});

export default verifiTheme;
