import React, { useRef } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import {
  AgencyOfficialRolesMap,
  EditOfficialPromiseInfo,
  RowMutations
} from './agencyOfficialsDataTable';
import { OfficialData, updateOfficial } from '../../../networkRequest/officialsService';
import { useUser } from '../../../hooks/useUser';

type EditAgencyOfficialsDialogProps = {
  open: boolean;
  editOfficialPromiseInfo: EditOfficialPromiseInfo | null;
  setEditOfficialPromiseInfo: React.Dispatch<React.SetStateAction<EditOfficialPromiseInfo | null>>;
  rowMutations: RowMutations;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
  resetRowMutations: () => void;
  saveSingleOfficialEdit: (OfficialData) => void;
  rolesMap: AgencyOfficialRolesMap;
};
export const EditAgencyOfficialsDialog = ({
  open,
  editOfficialPromiseInfo,
  setEditOfficialPromiseInfo,
  saveSingleOfficialEdit,
  rowMutations,
  setErrorMessage,
  setSuccessMessage,
  resetRowMutations,
  rolesMap
}: EditAgencyOfficialsDialogProps) => {
  const user = useUser();

  const noButtonRef = useRef<HTMLButtonElement>(null);
  const handleEnter = () => {
    if (!user) {
      throw new Error('User not found.');
    }
    // Force the focus to 'no' for UX purposes, see MUI docs for more information
    noButtonRef.current?.focus();
  };

  const handleYes = async () => {
    if (!user) {
      throw new Error('User not found');
    }

    if (!editOfficialPromiseInfo) {
      throw new Error('Error updating official.');
    }
    const { updatedRow, originalRow, resolve } = editOfficialPromiseInfo;

    type OfficialDataPartial = Partial<OfficialData>;
    type OfficialUpdate = OfficialDataPartial & { agency_official_type_id?: number } & {
      role_id?: number;
    };

    const officialUpdates: OfficialUpdate = {};
    switch (rowMutations.editedKey) {
      // for future refactoror, we can probably save the role id to the data we feed to the data grid via official formatter and tag the name on in the form of a label, assuming there's no custom component being rendered.
      case 'role_name':
        officialUpdates.role_id = rolesMap.get(rowMutations.updatedValue as string);
        break;
      default:
        officialUpdates[rowMutations.editedKey] = rowMutations.updatedValue;
    }

    officialUpdates.id = updatedRow.id;

    const response: { data?: OfficialData; error?: string } = await updateOfficial(
      user.accessToken,
      {
        ...officialUpdates
      }
    );

    if (response.error) {
      setErrorMessage(response.error);
      resolve(originalRow);
    }

    if (response.data) {
      saveSingleOfficialEdit(response.data);
      setSuccessMessage(
        `Updated ${rowMutations.originalLabel} to ${rowMutations.updatedLabel} successfully.`
      );
      resolve(response.data);
    }
    resetRowMutations();
    setEditOfficialPromiseInfo(null);
  };

  const handleNo = () => {
    if (!editOfficialPromiseInfo) throw new Error('Error editing official.');
    const { originalRow, resolve } = editOfficialPromiseInfo;
    resolve(originalRow);
    resetRowMutations();
    setEditOfficialPromiseInfo(null);
  };

  return (
    <Dialog
      data-testid="confirm-dialog"
      maxWidth="xs"
      TransitionProps={{ onEntered: handleEnter }}
      open={open}
    >
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent dividers>
        {`Pressing 'Yes' will change ${rowMutations.originalLabel} to ${rowMutations.updatedLabel || rowMutations.updatedValue}.`}
      </DialogContent>
      <DialogActions>
        <Button ref={noButtonRef} onClick={handleNo}>
          No
        </Button>
        <Button onClick={handleYes}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAgencyOfficialsDialog;
