import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { WarningAmber as Icon } from '@mui/icons-material';

interface ConfirmationProps {
  open: boolean;
  title?: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
}

export const Confirmation: React.FC<ConfirmationProps> = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
  confirmText = 'Delete',
  cancelText = 'Cancel'
}) => {
  return (
    <Dialog open={open} onClose={onCancel} maxWidth="xs" fullWidth>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent sx={{ padding: 3, paddingBottom: 0, textAlign: 'center' }}>
        <Icon color="error" sx={{ fontSize: 40, margin: 1 }} />
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button onClick={onCancel} color="secondary" variant="outlined">
          {cancelText}
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirmation;
