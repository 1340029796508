import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography } from '@mui/material';
import { createAuthAction } from '../../networkRequest/officialsService';
import { useUser } from '../../hooks/useUser';
import { styled } from '@mui/system';

interface LogoutButtonProps {
  textColor?: string;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ textColor = 'white' }) => {
  const { logout } = useAuth0();
  const user = useUser();
  const userLoading = !user?.profile?.id;
  const onLogout = async () => {
    if (user) {
      await createAuthAction(user.accessToken, user.profile.id, 'logout');
      logout({
        logoutParams: { returnTo: window.location.origin }
      });
    }
  };

  const StyledButton = styled(Button)({
    textDecoration: 'none',
    color: textColor,
    fontSize: '0.875rem',
    padding: '0'
  });

  return (
    <Box sx={{ mt: 1, paddingLeft: '16px' }}>
      <StyledButton
        variant="text"
        color="info"
        size="small"
        onClick={onLogout}
        disabled={userLoading}
      >
        <Typography textTransform={'capitalize'}>Sign Out</Typography>
      </StyledButton>
    </Box>
  );
};

export default LogoutButton;
