import {
  Box,
  Grid2 as Grid,
  IconButton,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { UploadIcon } from '../../assets/icons/icons';
import { Delete as TrashIcon } from '@mui/icons-material';
import React from 'react';
import { DropzoneFile } from './UploadDropzone';

export type PreviewDropzoneFile = DropzoneFile & { preview: string };

interface AddAgencyOfficialDialogUploadFileViewProps {
  uploadLoading: boolean;
  errorMessage: string;
  clearCurrentFile:
    | React.Dispatch<React.SetStateAction<PreviewDropzoneFile | null>>
    | ((acceptedFile: PreviewDropzoneFile | null) => Promise<void>);
  file: PreviewDropzoneFile | null;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

const AddAgencyOfficialDialogUploadFileView = ({
  uploadLoading,
  errorMessage,
  clearCurrentFile,
  file,
  setErrorMessage
}: AddAgencyOfficialDialogUploadFileViewProps) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const status = () => {
    if (uploadLoading) return 'Uploading';
    if (errorMessage) return 'Failed';
    return 'Complete';
  };

  const formatSize = (bytes) => {
    if (bytes >= 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else {
      return bytes + ' B';
    }
  };

  const clearFile = () => {
    setErrorMessage('');
    clearCurrentFile(null);
  };

  return (
    <Grid
      container
      spacing={isMediumScreen ? 1 : 2}
      sx={{
        paddingX: 2,
        backgroundColor: theme.palette.grey[50],
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        alignItems: 'center',
        justifyContent: isSmallScreen ? 'center' : '',
        flexDirection: isSmallScreen ? 'column' : 'row'
      }}
    >
      {file && file.type === 'image/heic' && (
        <Typography variant="caption" sx={{ width: '125px', padding: 3 }}>
          HEIC image preview not supported.
        </Typography>
      )}
      {file && file.type !== 'image/heic' ? (
        <Box
          component="img"
          src={file.preview}
          alt="official photo preview"
          sx={{
            width: 100,
            height: 100,
            borderRadius: theme.shape.borderRadius,
            margin: 2,
            marginY: 4,
            objectFit: 'cover'
          }}
        />
      ) : (
        <Grid size={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <UploadIcon sx={{ fontSize: 24 }} />
        </Grid>
      )}
      <Grid
        container
        sx={{ display: 'flex', justifyContent: 'space-between', flex: 1, flexWrap: 'nowrap' }}
      >
        <Grid container spacing={0.5} sx={{ display: 'flex', flexDirection: 'column' }}>
          <p
            style={{ color: errorMessage ? theme.palette.error.main : theme.palette.text.primary }}
          >
            {errorMessage ? 'Upload failed.' : file?.name}
          </p>
          <p
            style={{ color: errorMessage ? theme.palette.error.main : theme.palette.text.primary }}
          >
            {errorMessage ? errorMessage : formatSize(file?.size)} •{' '}
            <span>{errorMessage ? 'Failed' : status()}</span>
          </p>
          <Box>
            {uploadLoading ? (
              <LinearProgress />
            ) : (
              <LinearProgress variant="determinate" value={100} />
            )}
          </Box>
        </Grid>
        <Grid sx={{ display: 'flex', marginLeft: 'auto' }}>
          <IconButton aria-label="delete" onClick={clearFile}>
            <TrashIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddAgencyOfficialDialogUploadFileView;
