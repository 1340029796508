import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DataTableButton from './buttons/dataTableTopButton';
import { Box, useTheme } from '@mui/material';
import { ReactNode } from 'react';

const CustomToolBar = ({
  onAddClick,
  buttonText,
  children
}: {
  onAddClick: () => void;
  buttonText: string;
  children?: ReactNode;
}): JSX.Element => {
  const theme = useTheme();
  return (
    <GridToolbarContainer sx={{ backgroundColor: theme.palette.powderBlue, padding: 1 }}>
      <Box>
        <DataTableButton startIcon={<AddIcon />} buttonText={buttonText} onClick={onAddClick} />
      </Box>
      {children}
      <Box sx={{ marginLeft: 'auto' }}>
        <GridToolbarQuickFilter />
      </Box>
    </GridToolbarContainer>
  );
};
export default CustomToolBar;
