import {
  Box,
  Button,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid2 as Grid,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { createDynamicStringValidator } from '../../utils/validationTools';
import { z as zod } from 'zod';
import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import useReCaptcha from '../../hooks/useReCaptcha';
import PublicRegistrationFormInputs from './PublicRegistrationFormInputs';
import PublicRegistrationFormAutocomplete from './PublicRegistrationFormAutocomplete';
import { getAgencies, registerOfficial, uploadPublicPhoto } from './utils/data';
import UploadDropzone from '../../components/ImageUpload/UploadDropzone';
import { PreviewDropzoneFile } from '../../components/ImageUpload/AddAgencyOfficialDialogUploadFileView';
import { useDebounce } from '../../hooks/useDebounce';

export interface CustomErrorMap {
  [inputName: string]: string[];
}

interface PublicRegistrationFormProps {
  setGeneralError: React.Dispatch<React.SetStateAction<string>>;
  openDialog: (DialogSentiment) => void;
}

export interface NewOfficialData {
  first_name: string;
  last_name: string;
  title: string;
  rank: string;
  badge_number: string;
  agency_name: string;
  email: string;
  img_url: string;
}

const newOfficialInit: NewOfficialData = {
  first_name: '',
  last_name: '',
  title: '',
  rank: '',
  badge_number: '',
  agency_name: '',
  email: '',
  img_url: ''
};
export type NewOfficialWithToken = {
  agency_official: NewOfficialData & { google_recaptcha_token?: string | undefined };
};

const PublicRegistrationForm = ({ setGeneralError, openDialog }: PublicRegistrationFormProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { loading: reCaptchaLoading, generateReCaptchaToken } = useReCaptcha();
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<CustomErrorMap>({});
  const [newOfficialData, setNewOfficialData] = useState<NewOfficialData>(newOfficialInit);
  const [certified, setCertified] = useState(false);
  const [agencies, setAgencies] = useState<string[]>([]);
  const [agenciesLoading, setAgenciesLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [selectedFile, setSelectedFile] = useState<PreviewDropzoneFile | null>(null);
  const [formValidatedInitially, setFormValidatedInitially] = useState(false);

  const triggerFormValidation = useDebounce(async () => {
    validateForm();
  }, 500);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAgencies();
        setAgencies(data);
      } catch (error: any) {
        setGeneralError(error.message);
      }
      setAgenciesLoading(false);
    };

    fetchData();
  }, [setGeneralError]);

  useEffect(() => {
    if (formValidatedInitially) {
      triggerFormValidation();
    }
  }, [newOfficialData]);

  const officialValidationSchema = zod.object({
    first_name: zod.string().min(1, 'First Name is required.'),
    last_name: zod.string().min(1, 'Last name is required.'),
    title: zod.string().min(1, 'Title is required.'),
    email: zod.string().email(),
    rank: zod.string().min(1, 'Rank is required.'),
    agency_name: createDynamicStringValidator(agencies)
  });

  const validateForm = () => {
    try {
      officialValidationSchema.parse(newOfficialData);
      setFormErrors({});
      return true;
    } catch (formErrors) {
      if (formErrors instanceof zod.ZodError) {
        const formattedFormErrors: CustomErrorMap = formErrors.issues.reduce((acc, issue) => {
          const inputName = issue.path.join('.');
          return {
            ...acc,
            [inputName]: (acc[inputName] || []).concat(issue.message)
          };
        }, {} as CustomErrorMap);
        setFormErrors(formattedFormErrors);
      } else {
        setGeneralError('There was an error with the form.');
      }
      setLoading(false);
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const validForm = validateForm();
    setFormValidatedInitially(true);

    const data: { agency_official: NewOfficialData & { google_recaptcha_token?: string } } = {
      agency_official: { ...newOfficialData }
    };

    if (validForm) {
      try {
        const result = await generateReCaptchaToken('signup');
        if ('token' in result) {
          data.agency_official.google_recaptcha_token = result.token;
        } else if ('error' in result) {
          throw new Error(result.error);
        }
      } catch (e: any) {
        setGeneralError(e.message);
      }

      if (data.agency_official.google_recaptcha_token) {
        try {
          const responseStatus = await registerOfficial(data);
          if (responseStatus === 204) {
            openDialog('success');
            setNewOfficialData(newOfficialInit);
            setSelectedFile(null);
            setCertified(false);
          }
        } catch (error: any) {
          setGeneralError(error.message);
          openDialog('failure');
        } finally {
          setLoading(false);
        }
      } else {
        openDialog('failure');
      }
    }
  };

  const setFileUpload = async (acceptedFile: PreviewDropzoneFile | null) => {
    setUploadLoading(true);

    if (!acceptedFile) {
      setGeneralError('No File Selected.');
      return;
    }

    if (!newOfficialData.agency_name) {
      setUploadError('Must select agency first.');
      return;
    }

    setSelectedFile(acceptedFile);

    try {
      const data = await uploadPublicPhoto(acceptedFile, newOfficialData.agency_name);
      if (data) {
        // the flow for the admin version can't show an uploaded preview, it only works for the pre-upload (selectedFile), so we'll stick with that pattern until we change the flow for both?
        setNewOfficialData({ ...newOfficialData, img_url: data.secure_url });
      }
    } catch (error: any) {
      setUploadError(error.message);
    }
    setUploadLoading(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const sanitizedValue = DOMPurify.sanitize(value);
    setNewOfficialData({ ...newOfficialData, [name]: sanitizedValue });
  };

  const handleSelectChange = (_: React.SyntheticEvent, value: string | null) => {
    // since the only dropdown here is agency, we want to clear out the error if the user tried to upload before selecting the agency.
    if (uploadError) setUploadError('');
    if (value) {
      const sanitizedValue = DOMPurify.sanitize(value);
      setNewOfficialData({ ...newOfficialData, agency_name: sanitizedValue });
    }
  };

  const hasFormErrors = Object.keys(formErrors).length > 0;

  return (
    // this is weird but allows the inner content of the card to scroll instead of the whole card on small screens
    // without utilizing inline styles.
    <Box
      component="form"
      onSubmit={handleSubmit}
      role="form"
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
      }}
    >
      {reCaptchaLoading || agenciesLoading ? (
        <>
          <Box
            height="100%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size="25%" thickness={1} />
          </Box>
        </>
      ) : (
        <>
          <CardContent
            sx={{
              padding: 4,
              paddingY: 2,
              maxHeight: '75vh',
              position: 'relative',
              overflowY: 'scroll',
              overflowScrolling: 'touch',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            <Typography variant="h5">ID Verification for Security Checkpoints</Typography>
            <Typography
              sx={{
                marginY: {
                  md: 1,
                  lg: 2
                }
              }}
            >
              Create an Account
            </Typography>
            <Grid
              container
              spacing={3}
              padding={1}
              sx={{
                backgroundColor: theme.palette.grey[50],
                borderTopLeftRadius: theme.shape.borderRadius * 3,
                borderTopRightRadius: theme.shape.borderRadius * 3
              }}
            >
              <Grid container spacing={3} padding={1}>
                <PublicRegistrationFormInputs
                  newOfficialData={newOfficialData}
                  formErrors={formErrors}
                  handleInputChange={handleInputChange}
                />
                <PublicRegistrationFormAutocomplete
                  agencies={agencies}
                  handleSelectChange={handleSelectChange}
                  currentAgency={newOfficialData.agency_name}
                />
                <Grid size={12}>
                  <TextField
                    autoFocus
                    error={formErrors.email?.length > 0}
                    label="Work Email Address (Required)"
                    sx={{
                      '& .MuiInputLabel-outlined.Mui-focused': {
                        color: theme.palette.primary.dark
                      }
                    }}
                    name="email"
                    onChange={handleInputChange}
                    placeholder="Email@email.com"
                    slotProps={{
                      inputLabel: {
                        shrink: true
                      }
                    }}
                    value={newOfficialData.email}
                    fullWidth
                  />
                  <FormHelperText
                    error={formErrors.email?.length > 0}
                    sx={{ fontSize: '10pt', color: theme.palette.error.main }}
                  >
                    {formErrors.email}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid sx={{ marginBottom: 2, width: '100%' }}>
                <UploadDropzone
                  showPreview={!!selectedFile}
                  file={selectedFile}
                  setFile={setFileUpload}
                  clearSelectedFile={setSelectedFile}
                  setLoading={setUploadLoading}
                  loading={uploadLoading}
                  setErrorMessage={setUploadError}
                  setCanContinue={() => {}}
                  errorMessage={uploadError}
                  buttonOnly={isSmallScreen}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              paddingY={2}
              paddingX={4}
              sx={{
                backgroundColor: theme.palette.powderBlue,
                borderBottomRightRadius: theme.shape.borderRadius * 3
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={certified}
                    onChange={() => setCertified((prev) => !prev)}
                    name="certified"
                    color="primary"
                  />
                }
                sx={{ fontSize: '8pt' }}
                label={
                  <Typography sx={{ fontSize: '10pt', fontWeight: 'bold' }}>
                    I certify that the information provided is accurate and was obtained directly
                    from the Official. No third party was involved in providing this information.
                  </Typography>
                }
              />
            </Grid>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Button
              type="submit"
              size="small"
              color="primary"
              variant="contained"
              disableElevation
              disabled={!certified || uploadLoading || !newOfficialData.img_url || hasFormErrors}
              sx={{ borderRadius: 2, minWidth: '7rem', minHeight: '2.25rem' }}
            >
              {loading ? (
                <CircularProgress size="1rem" sx={{ color: theme.palette.text.primary }} />
              ) : (
                'Create Account'
              )}
            </Button>
            <Grid
              sx={{
                paddingTop: 3,
                fontSize: '10pt'
              }}
            >
              <Link color="textDisabled" href="#" sx={{ marginRight: 1 }}>
                Terms of Service
              </Link>
              <Link color="textDisabled" href="#" sx={{ marginLeft: 1 }}>
                Privacy Policy
              </Link>
            </Grid>
          </CardActions>
        </>
      )}
    </Box>
  );
};

export default PublicRegistrationForm;
