import {
  Grid2 as Grid,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Stack,
  Typography,
  SelectChangeEvent,
  useTheme,
  Box,
  useMediaQuery
} from '@mui/material';
import { FormOfficialData } from './AddAgencyOfficialDialogForm';
import React, { useMemo } from 'react';
import { z as zod } from 'zod';
import { ArmedIcon } from '../../../../../assets/icons/icons';
import { AgencyOfficialRolesMap, AgencyOfficialTypesMap } from '../../agencyOfficialsDataTable';
import { Agency } from '../../../../../networkRequest/agenciesService';
import { employmentTypeOptions } from '../../../../../networkRequest/officialsService';

interface AddAgencyOfficialDialogContentProps {
  handleChange: {
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (e: SelectChangeEvent) => void;
    handleSwitchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
  setFormOfficialData: React.Dispatch<React.SetStateAction<FormOfficialData>>;
  formOfficialData: FormOfficialData;
  types: AgencyOfficialTypesMap;
  agencies: Agency[];
  roles: AgencyOfficialRolesMap;
  formErrors: zod.ZodError<any> | null;
}

const AddAgencyOfficialDialogContent = ({
  handleChange,
  formOfficialData,
  types,
  agencies,
  roles,
  formErrors
}: AddAgencyOfficialDialogContentProps) => {
  const theme = useTheme();
  const padding = theme.spacing(3);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const inputs = [
    {
      name: 'first_name',
      label: 'First Name (Required)',
      placeholder: 'First Name',
      value: formOfficialData.first_name,
      required: true
    },
    {
      name: 'last_name',
      label: 'Last Name (Required)',
      placeholder: 'Last Name',
      value: formOfficialData.last_name,
      required: true
    },
    {
      name: 'title',
      label: 'Title (Required)',
      placeholder: 'Overnight Facility Security Agent',
      value: formOfficialData.title,
      required: true
    },
    {
      name: 'email',
      label: 'Email address (Required)',
      placeholder: 'Email@gmail.com',
      value: formOfficialData.email,
      required: true
    }
  ];

  const selects = useMemo(
    () => [
      {
        id: 'role-select',
        name: 'role',
        label: 'Role (Required)',
        value: formOfficialData.role,
        'data-testid': 'role-select',
        required: true,
        menuItems: Array.from(roles).map(([key, value]) => {
          return (
            <MenuItem key={value} value={key} selected={formOfficialData.role === key}>
              {key}
            </MenuItem>
          );
        })
      }
    ],
    [formOfficialData.role, roles]
  );

  return (
    <DialogContent sx={{ padding }}>
      <Grid
        container
        spacing={3}
        padding={1}
        sx={{
          backgroundColor: theme.palette.grey[50],
          borderTopLeftRadius: theme.shape.borderRadius * 3,
          borderTopRightRadius: theme.shape.borderRadius * 3
        }}
      >
        <Grid container spacing={3} padding={1}>
          {inputs.map((input) => (
            <Grid size={isSmallScreen ? 12 : 6} key={input.name}>
              <TextField
                autoFocus
                error={!!formErrors?.formErrors.fieldErrors[input.name]}
                label={input.label}
                sx={{
                  '& .MuiInputLabel-outlined.Mui-focused': {
                    color: theme.palette.primary.dark
                  }
                }}
                name={input.name}
                onChange={handleChange.handleInputChange}
                placeholder={input.placeholder}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                value={input.value}
                fullWidth
              />
            </Grid>
          ))}
          {selects.map((select) => (
            <Grid size={isSmallScreen ? 12 : 6} key={`key-${select.id}`}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel
                  htmlFor={select.id}
                  sx={{ '&.MuiInputLabel-outlined': { color: theme.palette.primary.dark } }}
                >
                  {select.label}
                </InputLabel>
                <Select
                  id={select.id}
                  data-testid={select['data-testid']}
                  name={select.name}
                  value={select.value}
                  onChange={handleChange.handleSelectChange}
                  label={select.label}
                >
                  {select.menuItems}
                </Select>
              </FormControl>
            </Grid>
          ))}
          <Grid size={isSmallScreen ? 12 : 6}>
            <TextField
              label="Rank (Optional)"
              sx={{
                '& .MuiInputLabel-outlined.Mui-focused': {
                  color: theme.palette.primary.dark
                }
              }}
              name="rank"
              onChange={handleChange.handleInputChange}
              placeholder="Sergeant"
              slotProps={{
                inputLabel: {
                  shrink: true
                }
              }}
              value={formOfficialData.rank}
              fullWidth
            />
          </Grid>
          <Grid size={isSmallScreen ? 12 : 6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel
                htmlFor="type-select"
                sx={{ '&.MuiInputLabel-outlined': { color: theme.palette.primary.dark } }}
              >
                Type (Optional)
              </InputLabel>
              <Select
                id="type-select"
                name="agency_official_type_id"
                value={
                  formOfficialData.agency_official_type_id
                    ? types.get(formOfficialData.agency_official_type_id)?.name
                    : 'None'
                }
                onChange={handleChange.handleSelectChange}
                label="Type (Optional)"
              >
                <MenuItem value="None">None</MenuItem>
                {Array.from(types).map(([, value]) => (
                  <MenuItem
                    key={value.name}
                    value={value.name}
                    selected={
                      types.get(formOfficialData.agency_official_type_id!)?.name === value.name
                    }
                  >
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid size={isSmallScreen ? 12 : 6}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel
                htmlFor="employment_type-select"
                sx={{ '&.MuiInputLabel-outlined': { color: theme.palette.primary.dark } }}
              >
                Employment Type (Required)
              </InputLabel>
              <Select
                id="employment_type-select"
                name="employment_type"
                value={formOfficialData.employment_type}
                onChange={handleChange.handleSelectChange}
                label="Employment Type (Required)"
              >
                {employmentTypeOptions.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {formOfficialData.employment_type === 'law_enforcement' && (
            <Grid size={isSmallScreen ? 12 : 6}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel
                  htmlFor="agency-select"
                  sx={{ '&.MuiInputLabel-outlined': { color: theme.palette.primary.dark } }}
                >
                  Law Enforcement Agency (Required)
                </InputLabel>
                <Select
                  id="agency-select"
                  name="agency"
                  value={formOfficialData.agency || ''}
                  onChange={handleChange.handleSelectChange}
                  label="Law Enforcement Agency (Required)"
                >
                  <MenuItem value={''} disabled selected={formOfficialData.agency === ''}>
                    Select Agency
                  </MenuItem>
                  {agencies.map((agency) => (
                    <MenuItem
                      key={agency.id}
                      value={agency.name}
                      selected={formOfficialData.agency === agency.name}
                    >
                      {agency.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        mt={1}
        sx={{
          backgroundColor: theme.palette.grey[50],
          borderRadius: theme.shape.borderRadius
        }}
      >
        <Grid
          padding={padding}
          size={isSmallScreen && 12}
          sx={{
            backgroundColor: theme.palette.powderBlue,
            borderRadius: theme.shape.borderRadius,
            display: isSmallScreen ? 'flex' : 'inerit',
            justifyContent: isSmallScreen ? 'center' : 'inerit'
          }}
        >
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <Typography>No</Typography>
            <Switch
              name="armed_permission"
              data-testid="armed_permission"
              checked={formOfficialData.armed_permission}
              onChange={handleChange.handleSwitchChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>Yes</Typography>
          </Stack>
        </Grid>
        <Grid container padding={padding} alignItems="center">
          <Box
            sx={{
              fontWeight: 600,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <ArmedIcon />
            <Typography sx={{ ml: 1 }}>Permission to be armed</Typography>
          </Box>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default AddAgencyOfficialDialogContent;
