import {
  Box,
  DialogContent,
  Grid2 as Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import UploadDropzone from '../../../../../components/ImageUpload/UploadDropzone';
import { UserIcon } from '../../../../../assets/icons/icons';
import React, { useState } from 'react';
import AddAgencyOfficialDialogActions from '../AddAgencyOfficialDialogActions';
import { useUser } from '../../../../../hooks/useUser';
import { PreviewDropzoneFile } from '../../../../../components/ImageUpload/AddAgencyOfficialDialogUploadFileView';
import { updateOfficialImage } from '../../../../../networkRequest/officialsService';

const imageUploadRules = {
  explanation:
    'Please ensure your photo adheres to the specified guidelines to avoid rejection and resubmission.',
  rules: [
    'Do not use a photo of a photo',
    'Background should be a white or light solid color',
    'The photo must be in color',
    'You must be the only person in the photo',
    'Include and center your full head and shoulders only (Like a passport photo)',
    'Your face must be clearly visible and facing the camera',
    'You may NOT wear sunglasses, hats, or other objects in the photo (religious headware is acceptable)',
    'You may smile, but no other gestures are permitted'
  ]
};

interface AddAgencyOfficialDialogUploadProps {
  newOfficialId: number | undefined;
  onClose: () => void;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

const AddAgencyOfficialDialogUpload = ({
  newOfficialId,
  onClose,
  setActiveStep,
  setErrorMessage
}: AddAgencyOfficialDialogUploadProps) => {
  const user = useUser();
  const theme = useTheme();
  const padding = theme.spacing(3);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [file, setFile] = useState<PreviewDropzoneFile | null>(null);
  const [canContinue, setCanContinue] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [errorUploadMessage, setErrorUploadMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUploadLoading(true);

    if (file) {
      // two try catch to display the cloudinary error differently from the api error.
      try {
        if (!user) {
          throw new Error('User not found');
        }
        if (!newOfficialId) {
          throw new Error('No official id.');
        }
        const { data, error } = await updateOfficialImage(
          user.accessToken,
          newOfficialId,
          user.profile.agency.id,
          file
        );
        if (data) {
          setActiveStep(2);
        } else if (error) {
          setErrorMessage(error);
        }
      } catch (error: any) {
        // show error in component for cloudinary error.
        setErrorUploadMessage(error.message);
      } finally {
        setUploadLoading(false);
      }
    }
  };

  return (
    <DialogContent sx={{ padding }}>
      <form onSubmit={handleSubmit} role="form">
        <Grid container spacing={1} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid
            container
            spacing={1}
            sx={{
              paddingY: 2,
              paddingX: 5,
              backgroundColor: theme.palette.grey[50],
              borderRadius: theme.shape.borderRadius,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Grid size={12} sx={{ display: 'flex' }}>
              <Grid size={isMediumScreen ? 2 : 1}>
                <UserIcon sx={{ fontSize: 34 }} />
              </Grid>
              <Grid
                size={isMediumScreen ? 10 : 11}
                rowGap={10}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography variant="h6">
                  SurePass requires a recent photo for in-person identity verification.
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ fontWeight: '600', fontSize: '11pt', marginLeft: '8%' }}>
              {imageUploadRules.explanation}
              <List sx={{ listStyleType: 'disc', pl: 3 }}>
                {imageUploadRules.rules.map((rule) => (
                  <ListItem key={rule} sx={{ display: 'list-item', padding: 0 }}>
                    {rule}
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
          <Grid>
            <UploadDropzone
              file={file}
              showPreview={!!file}
              setFile={setFile}
              clearSelectedFile={setFile}
              setLoading={setUploadLoading}
              loading={uploadLoading}
              setErrorMessage={setErrorUploadMessage}
              setCanContinue={setCanContinue}
              errorMessage={errorUploadMessage}
            />
          </Grid>
        </Grid>
        <AddAgencyOfficialDialogActions
          onClose={onClose}
          padding={padding}
          canContinue={canContinue}
          loading={uploadLoading}
        />
      </form>
    </DialogContent>
  );
};

export default AddAgencyOfficialDialogUpload;
