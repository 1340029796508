import React from 'react';
import { Box, Typography } from '@mui/material';
import { LocationIcon } from '../../../assets/icons/icons';

interface AvatarCellProps {
  name: string;
}

const AvatarCell: React.FC<AvatarCellProps> = ({ name }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, height: '100%' }}>
    <LocationIcon />
    <Typography variant="body2">{name}</Typography>
  </Box>
);
export default AvatarCell;
