export default function formatDate(
  timestamp: string,
  locale: string = 'en-US'
): { date: string; time: string } {
  if (!timestamp) {
    return { date: '', time: '' };
  }

  const date = new Date(timestamp);

  // Check for invalid date
  if (isNaN(date.getTime())) {
    return { date: 'Invalid Date', time: 'Invalid Date' };
  }

  return {
    date: new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date),
    time: new Intl.DateTimeFormat(locale, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    }).format(date)
  };
}
