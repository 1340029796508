import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid2 as Grid,
  useTheme,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from '@mui/material';
import { addCredential, CredentialInput } from '../../../networkRequest/officialsService';
import { useUser } from '../../../hooks/useUser';

type CredentialDialogProps = {
  open: boolean;
  officialId: number;
  onClose: () => void;
  onSubmit?: (formData: CredentialInput) => void;
  onError: (error: string) => void;
};

export const AddCredentialDialog = ({
  open,
  officialId,
  onClose = () => {},
  onSubmit = () => {},
  onError
}: CredentialDialogProps) => {
  const user = useUser();
  const theme = useTheme();
  const padding = theme.spacing(4);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!user) {
      throw new Error('User not found');
    }
    const type = (e.currentTarget.elements.namedItem('type') as HTMLSelectElement)?.value;
    const response = await addCredential(user.accessToken, officialId, {
      credential_type: type
    });
    if (response.error) {
      onError(response.error);
    } else if (response.data) {
      onSubmit?.(response.data);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit} role="form">
        <DialogTitle sx={{ padding }}>Add Credential</DialogTitle>
        <DialogContent sx={{ padding }}>
          <Grid container spacing={3} padding={1}>
            <Grid size={12}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel>Credential Type</InputLabel>
                <Select name="type" label="Credential Type" defaultValue={'mobile'} fullWidth>
                  <MenuItem value="mobile">Mobile</MenuItem>
                  <MenuItem value="sticker">Sticker</MenuItem>
                  <MenuItem value="card">Card</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding, pt: 0, mr: 1 }}>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddCredentialDialog;
