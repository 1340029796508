import { useEffect, useMemo, ReactNode } from 'react';
import styled from '@mui/system/styled';
import { Alert, AlertTitle, AlertColor, useTheme } from '@mui/material';

type ToastType = AlertColor;
type Color = { fg: string; bg: string };

type ToastProps = {
  type: ToastType; // 'success' | 'error' | 'info' | 'warning'
  children: ReactNode;

  // Optional, but if not provided, the toast will not dismiss
  // By default, the toast will dismiss after 5 seconds
  // unless the type is 'error', in which case it will not dismiss
  timeout?: number;

  // Optional, but if not provided, the toast will not be dismissible
  onClose?: () => void;
};

const Title = styled(AlertTitle)({
  textTransform: 'uppercase'
});

export const Toast = ({
  type,
  children,
  timeout = type === 'error' ? 0 : 5000,
  onClose
}: ToastProps) => {
  const theme = useTheme();
  const colors: Record<ToastType, Color> = useMemo(
    () => ({
      success: {
        fg: 'black',
        bg: theme.palette.success.main
      },
      error: {
        fg: 'white',
        bg: theme.palette.error.main
      },
      info: {
        fg: 'white',
        bg: theme.palette.info.main
      },
      warning: {
        fg: 'black',
        bg: theme.palette.warning.main
      }
    }),
    [theme]
  );

  useEffect(() => {
    if (timeout > 0) {
      const timer = setTimeout(() => onClose?.(), timeout);
      return () => clearTimeout(timer);
    }
  }, [timeout, onClose]);

  return (
    <Alert
      onClose={onClose}
      sx={{
        maxWidth: 320,
        margin: `${theme.spacing(3)} auto`,
        color: colors[type].fg,
        backgroundColor: colors[type].bg,
        position: 'fixed',
        bottom: theme.spacing(2),
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1400
      }}
      elevation={6}
      variant="filled"
      severity={type}
    >
      <Title>{type}</Title>
      {children}
    </Alert>
  );
};

export default Toast;
