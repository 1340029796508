import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth0ProviderWithNavigate from './auth/auth0-provider-with-navigate';
import App from './App';
import './index.css';
import { UserProvider } from './hooks/useUser';

// more configuration will be avilable with React 19, so check it out when we update.
// if we end up adding redux or tanstack, we can also configure that with Sentry.
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_URL,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: []
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Router>
      <Auth0ProviderWithNavigate>
        <ThemeProvider theme={theme}>
          <UserProvider>
            <App />
          </UserProvider>
        </ThemeProvider>
      </Auth0ProviderWithNavigate>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
