import { List, ListItem, ListItemText, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled, useMediaQuery } from '@mui/system';
import copy from './pageLayout/definitions/copy';
import { useUser } from '../hooks/useUser';
import navBarSurePassLogo from '../assets/navBarSurePassLogo.svg';
import placeholderLogo from '../assets/navBarPlaceholder.svg';
import LogoutButton from '../components/buttons/logoutButton';

const HeaderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  paddingBottom: '16px'
}));

const LogoContainer = styled('div')({
  height: '147px',
  width: '243px',
  display: 'flex',
  flexDirection: 'column',
  margin: '24px',
  backgroundColor: '#fefefe',
  borderRadius: '10px',
  padding: '4px 0',
  overflow: 'hidden'
});

const LogoImage = styled('img')({
  height: '100%',
  width: '100%',
  margin: 'auto',
  padding: '12px',
  objectFit: 'contain'
});

const PoweredByText = styled(Typography)({
  color: '#E0E0E0',
  fontSize: '0.6rem',
  fontWeight: 500,
  margin: '3px 24px',
  textTransform: 'uppercase',
  letterSpacing: '0.12em'
});

// Styled NavLink for MUI compatibility
const StyledNavLink = styled(Link)({
  textDecoration: 'none',
  color: '#FFFFFF',
  fontSize: '0.875rem'
});

interface NavItemProps {
  to: string;
  label: string;
  external?: boolean;
}
const NavItem = ({ to, label, external }: NavItemProps) => {
  const linkProps = external ? { to, rel: 'noopener noreferrer', target: '_blank' } : { to };
  return (
    <ListItem key={label} component="li">
      <StyledNavLink {...linkProps}>
        <ListItemText primary={label} />
      </StyledNavLink>
    </ListItem>
  );
};

const Sidebar = () => {
  const isSmallScreen = useMediaQuery('(max-width:1024px)');
  const user = useUser();
  const DrawerHeader = () => (
    <HeaderContainer>
      <LogoContainer>
        <LogoImage src={user?.profile.agency.logo_url || placeholderLogo} alt="Badge Logo" />
      </LogoContainer>
      <PoweredByText>Powered By</PoweredByText>
      <Box
        component="img"
        src={navBarSurePassLogo}
        alt="SurePass logo"
        sx={{
          width: '120px',
          height: 'auto',
          margin: '0 24px'
        }}
      />
    </HeaderContainer>
  );
  return (
    <>
      {!isSmallScreen && <DrawerHeader />}
      {isSmallScreen && <Box sx={{ height: '86px', backgroundColor: 'transparent' }} />}
      <List sx={{ padding: '12px' }}>
        <NavItem to="/" label={copy.menuTitle.home} />
        <NavItem to="/manage-officials" label={copy.menuTitle.manageOfficials} />
        <NavItem to="/locations" label={copy.menuTitle.locations} />
        <NavItem to="/checkpoints" label={copy.menuTitle.checkpoints} />
        <NavItem to="/access-logs" label={copy.menuTitle.accessLogs} />
        <NavItem
          to="https://form.jotform.com/243653092672157"
          label={copy.menuTitle.help}
          external={true}
        />
        <LogoutButton />
      </List>
      <Box sx={{ position: 'absolute', bottom: '16px', left: '24px' }}>
        <Typography variant="body2" sx={{ color: '#FFFFFF', opacity: 0.5 }}>
          {copy.copyrightFooter}
        </Typography>
      </Box>
    </>
  );
};

export default Sidebar;
