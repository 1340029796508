import ImageSelector from './ImageSelector';
import CredentialsList from './CredentialsList';
import UserAvatar from './UserAvatar';
import { GroupsIcon } from '../../../assets/icons/icons';
import { UserDetailData } from '../utils/data';
import { Box, Typography, Divider } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Toast } from '../../../components/toast/Toast';
import { updateOfficialImage } from '../../../networkRequest/officialsService';
import { useUser } from '../../../hooks/useUser';
import CircularProgress from '@mui/material/CircularProgress';
import { validateOfficialImage } from '../../../utils/dropzoneImageValidator';
import {
  useEffect,
  useState,
  ReactNode,
  useCallback,
  cloneElement,
  Children,
  ReactElement
} from 'react';
import copy from '../../../containers/pageLayout/definitions/copy';

type RowProps = {
  index?: number;
  label: string;
  value?: string;
  icon?: ReactNode;
  capitalize?: boolean;
};

type RowImplProps = RowProps & {
  index: number;
};

const RowImpl = ({ label, value, icon, index, capitalize = true }: RowImplProps) => (
  <Box p={1} bgcolor={index % 2 === 1 ? 'white' : 'grey.100'}>
    <Typography variant="body2" color="secondary" gutterBottom>
      {label} {icon}
    </Typography>
    <Typography textTransform={capitalize ? 'capitalize' : 'none'}>{value || 'None'}</Typography>
  </Box>
);

const Row = ({ index = 0, ...rest }: RowProps) => <RowImpl index={index} {...rest} />;

const Rows = ({ children }: { children: ReactNode }) =>
  Children.toArray(children).map((child, index) =>
    cloneElement(child as ReactElement, { index } as RowProps)
  );

const leftWidth = 260;

interface UserDetailViewProps {
  data: UserDetailData;
  refreshData: () => Promise<void>;
  setData: (UserDetailData) => void;
}

export default function UserDetailView({
  data: { official },
  setData,
  refreshData
}: UserDetailViewProps) {
  const [error, setError] = useState('');
  const user = useUser();
  const [imgUploading, setImgUploading] = useState(false);

  const onDropAccepted = useCallback(async (file) => {
    if (user) {
      setImgUploading(true);
      const { data, error } = await updateOfficialImage(
        user.accessToken,
        official.id,
        user.profile.agency.id,
        file[0]
      );
      if (data) {
        setData({ official: data });
      } else if (error) {
        console.error(error);
        setError(error);
      }
      setImgUploading(false);
    }
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDropAccepted,
    maxFiles: 1,
    validator: validateOfficialImage
  });

  useEffect(() => {
    if (fileRejections.length > 0) {
      let error = fileRejections[0].errors[0].message;
      if (error.includes('Unsuported file') || error.includes('File too large')) {
        error += `. ${copy.imageUpload.fileType}`;
      }
      setError(error);
    }
  }, [fileRejections, setError]);

  return (
    <>
      <Box display="flex" gap={3} mb={6} mt={1}>
        <Box width={leftWidth} display="flex" flexDirection="column" gap={2}>
          {imgUploading ? (
            <>
              <Box
                width={leftWidth}
                height={leftWidth}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size="50%" />
              </Box>
            </>
          ) : (
            <Box {...getRootProps()}>
              <Box width={leftWidth} height={leftWidth} sx={{ mb: 2 }}>
                <UserAvatar picture={official.img_url} />
              </Box>
              <ImageSelector dropzoneInputProps={getInputProps()} />
            </Box>
          )}
        </Box>
        <Box flex={1}>
          <Typography variant="h5" pt={1} pb={2}>
            {`${official.first_name} ${official.last_name}`}
          </Typography>
          <Rows>
            <Row label="Agency" value={official.agency.name} />
            <Row label="Badge" value={official.badge_number} />
            <Row label="Role" value={official.role?.name || 'N/A'} />
            <Row label="Title" value={official.title} />
            <Row label="Rank" value={official.rank} />
            <Row label="Verified" value={official.verified ? 'Yes' : 'No'} />
            <Row label="Email" value={official.email} capitalize={false} />
            <Row label="Status" value={official.status} />
            <Row label="Official Type" value={official.agency_official_type?.name} />
            <Row label="Employment Type" value={official.employment_type} />
            <Row
              label="Groups"
              icon={
                <GroupsIcon
                  sx={{
                    height: '12px',
                    width: '20px',
                    marginBottom: '-1px'
                  }}
                />
              }
              value={official.groups.map((g) => g.name).join(', ')}
            />
          </Rows>
        </Box>
      </Box>
      <Divider />
      <CredentialsList
        officialId={official.id}
        credentials={official.official_credentials}
        refreshData={refreshData}
      />
      {error && (
        <Toast type="error" onClose={() => setError('')}>
          {error}
        </Toast>
      )}
    </>
  );
}
