import { Chip } from '@mui/material';
import styled from '@mui/system/styled';
import { Error } from '@mui/icons-material';

const getIconColor = (status: string) => {
  switch (status) {
    case 'active':
      return undefined;
    default:
      return 'error';
  }
};

const getColor = (status: string) => {
  switch (status) {
    case 'active':
      return 'text.primary';
    default:
      return 'error.dark';
  }
};

const getBgColor = (status: string) => {
  switch (status) {
    case 'active':
      return 'transparent';
    default:
      return 'yellow.100';
  }
};

const getVariant = (status: string) => {
  switch (status) {
    case 'active':
      return 'outlined';
    default:
      return 'filled';
  }
};

const getHeight = (status: string) => {
  switch (status) {
    case 'active':
      return 26;
    default:
      return 28;
  }
};

const StyledChip = styled(Chip)({
  borderRadius: 16,
  svg: {
    height: 20,
    width: 20
  }
});

export const StatusChip = ({ value }: { value: string }) => (
  <StyledChip
    label={value}
    color={getIconColor(value)}
    variant={getVariant(value)}
    sx={{
      color: getColor(value),
      backgroundColor: getBgColor(value),
      height: getHeight(value)
    }}
    icon={value === 'active' ? undefined : <Error />}
    data-testid="status-chip"
  />
);

export default StatusChip;
