import { Dialog, DialogTitle, IconButton, useTheme } from '@mui/material';
import { AgencyOfficialRolesMap, AgencyOfficialTypesMap } from '../agencyOfficialsDataTable';
import { Agency } from '../../../../networkRequest/agenciesService';
import AddAgencyOfficialDialogSteps from './AddAgencyOfficialDialogSteps';
import AddAgencyOfficialDialogForm from './Step 1/AddAgencyOfficialDialogForm';
import React, { useState } from 'react';
import AddAgencyOfficialDialogUpload from './Step 2/AddAgencyOfficialDialogUpload';
import Toast from '../../../../components/toast/Toast';
import AddAgencyOfficialDialogCongratulations from './Step 3/AddAgencyOfficialDialogCongratulations';
import { OfficialData } from '../../../../networkRequest/officialsService';
import CloseIcon from '@mui/icons-material/Close';

type AddAgencyOfficialDialogProps = {
  open: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  types: AgencyOfficialTypesMap;
  typesByName: AgencyOfficialRolesMap;
  agencies: Agency[];
  roles: AgencyOfficialRolesMap;
  onClose: () => void;
};

const AddAgencyOfficialDialog = ({
  open,
  setDialogOpen,
  types,
  typesByName,
  roles,
  agencies,
  onClose
}: AddAgencyOfficialDialogProps) => {
  const theme = useTheme();
  const padding = theme.spacing(3);
  const [activeStep, setActiveStep] = useState(0);

  const [errorMessage, setErrorMessage] = useState('');
  const [newOfficial, setNewOfficial] = useState<OfficialData | null>(null);

  const closeDialog = () => {
    setDialogOpen(false);
    setActiveStep(0);
    setNewOfficial(null);
    onClose();
  };

  return (
    <>
      {errorMessage && (
        <Toast type="error" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Toast>
      )}
      <Dialog
        open={open}
        onClose={closeDialog}
        maxWidth="md"
        fullWidth
        slotProps={{ backdrop: { invisible: true } }}
      >
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon sx={{ fontSize: 32, color: theme.palette.grey[600] }} />
        </IconButton>
        <DialogTitle sx={{ padding }}>Add Official</DialogTitle>
        <AddAgencyOfficialDialogSteps activeStep={activeStep} />
        {activeStep === 0 && (
          <AddAgencyOfficialDialogForm
            onClose={closeDialog}
            onError={setErrorMessage}
            types={types}
            agencies={agencies}
            roles={roles}
            padding={padding}
            setActiveStep={setActiveStep}
            setNewOfficial={setNewOfficial}
            typesByName={typesByName}
          />
        )}
        {activeStep === 1 && (
          <AddAgencyOfficialDialogUpload
            onClose={closeDialog}
            newOfficialId={newOfficial?.id}
            setActiveStep={setActiveStep}
            setErrorMessage={setErrorMessage}
          />
        )}
        {activeStep === 2 && (
          <AddAgencyOfficialDialogCongratulations
            newOfficialName={`${newOfficial?.first_name} ${newOfficial?.last_name}`}
            onClose={closeDialog}
          />
        )}
      </Dialog>
    </>
  );
};

export default AddAgencyOfficialDialog;
