import { UserState } from "../../../hooks/useUser";
import {
  OfficialData, getOfficial
} from '../../../networkRequest/officialsService';

export type UserDetailData = {
  official: OfficialData
};

export const getData = async (
  user: UserState,
  officialId: number
): Promise<UserDetailData> => {
  const { data, error } = await getOfficial(user.accessToken, officialId);
  if (error) {
    throw new Error(error);
  } else if (!data) {
    throw new Error('No data returned');
  }
  return { 
    official: data
  };
};
