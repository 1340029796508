import { useState, useEffect } from 'react';
import {
  addAgencyLocation,
  Location,
  updateAgencyLocation
} from '../../../networkRequest/locationsService';
import { useUser } from '../../../hooks/useUser';
import {
  Grid2 as Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  useTheme
} from '@mui/material';
import { STATES } from '../../../constants';

type LocationDialogProps = {
  title?: string;
  open: boolean;
  location?: Location; // undefined for add, defined for edit
  onClose?: () => void;
  onSubmit?: (formData: Location) => void;
  onChange?: ({ name, value }: { name: string; value: string }) => void;
  onError: (error: string) => void;
};

const defaultLocation: Location = {
  id: 0, // or make it optional in the Location type for new entries
  agency_id: 0,
  name: '',
  street_address_1: '',
  street_address_2: '',
  city: '',
  state: '',
  postal: ''
};

export const EditLocationDialog = ({
  open,
  location: initLocation,
  title = initLocation ? 'Edit Location' : 'Add Location',
  onClose = () => {},
  onSubmit = () => {},
  onChange = () => {},
  onError
}: LocationDialogProps) => {
  const user = useUser();
  const theme = useTheme();
  const padding = theme.spacing(4);

  const [location, setLocation] = useState<Location>(initLocation || defaultLocation);
  useEffect(() => {
    if (initLocation) {
      setLocation(initLocation);
    } else {
      setLocation(defaultLocation);
    }
  }, [initLocation, open]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!user) {
      throw new Error('User not found');
    }
    const isEdit = Boolean(initLocation);
    const data = { ...location, id: initLocation?.id || location.id };
    const response = isEdit
      ? await updateAgencyLocation(user.accessToken, data)
      : await addAgencyLocation(user.accessToken, user.profile.agency.id, data);

    if (response.error) {
      onError(response.error);
    } else if (response.data) {
      onSubmit?.(response.data);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLocation((prevState) => ({
      ...prevState,
      [name]: value
    }));
    onChange?.({ name, value });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setLocation((prevState) => ({
      ...prevState,
      [name]: value
    }));
    onChange?.({ name, value });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit} role="form">
        <DialogTitle sx={{ padding }}>{title}</DialogTitle>
        <DialogContent sx={{ padding }}>
          <Grid container spacing={3} padding={1}>
            <Grid size={12}>
              <TextField
                autoFocus
                label="Location Name"
                name="name"
                value={location.name || ''}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid size={6}>
              <TextField
                label="Street Address"
                name="street_address_1"
                value={location.street_address_1 || ''}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid size={6}>
              <TextField
                label="Address 2"
                name="street_address_2"
                value={location.street_address_2 || ''}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid size={6}>
              <TextField
                label="City"
                name="city"
                value={location.city || ''}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid size={6}>
              <FormControl fullWidth required>
                <InputLabel>State</InputLabel>
                <Select
                  name="state"
                  value={location.state || ''}
                  onChange={handleSelectChange}
                  label="State"
                >
                  <MenuItem value="" disabled>
                    Select State
                  </MenuItem>
                  {STATES.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid size={6}>
              <TextField
                label="ZIP Code"
                name="postal"
                value={location?.postal || ''}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding, pt: 0, mr: 1 }}>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditLocationDialog;
