import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { copy } from '../../containers/pageLayout/definitions';

export type DialogSentiment = 'success' | 'failure' | null;

interface PublicRegistrationConfirmationDialogProps {
  sentiment: DialogSentiment;
  setSentiment: React.Dispatch<React.SetStateAction<DialogSentiment>>;
}

const PublicRegistrationConfirmationDialog = ({
  sentiment,
  setSentiment
}: PublicRegistrationConfirmationDialogProps) => {
  const theme = useTheme();

  const onClose = () => {
    setSentiment(null);
  };

  return (
    <Dialog
      open={!!sentiment}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{ sx: { borderRadius: theme.shape.borderRadius / 2 } }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500]
        })}
      >
        <CloseIcon sx={{ fontSize: 32, color: theme.palette.grey[600] }} />
      </IconButton>
      <DialogTitle sx={{ textAlign: 'center', paddingBottom: 2, paddingTop: 4 }}>
        {sentiment === 'success' && <CheckCircleOutlinedIcon sx={{ fontSize: '28pt' }} />}
        {sentiment === 'failure' && (
          <WarningAmberOutlinedIcon color="error" sx={{ fontSize: '28pt' }} />
        )}
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center', marginX: { md: 10 } }}>
        {!!sentiment && copy.publicRegistration[sentiment]}
      </DialogContent>
      <DialogActions sx={{ paddingBottom: 2, paddingRight: 2 }}>
        <Button onClick={onClose} variant="outlined" color="secondary" sx={{ borderRadius: 2 }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PublicRegistrationConfirmationDialog;
