import { Avatar, Grid2 as Grid, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ArrowButtonLink = () => {
  const theme = useTheme();

  return (
    <Grid sx={{ display: 'flex', alignSelf: 'flex-end' }} data-testid="arrow-button-link">
      <Avatar sx={{ backgroundColor: theme.palette.secondary.main }}>
        <ArrowForwardIcon sx={{ color: theme.palette.brightTeal }} />
      </Avatar>
    </Grid>
  );
};

export default ArrowButtonLink;
