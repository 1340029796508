import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { GridColDef, GridMoreVertIcon } from '@mui/x-data-grid';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { AgencyOfficialRolesMap, AgencyOfficialTypesMap } from './agencyOfficialsDataTable';
import { OfficialData } from '../../../networkRequest/officialsService';
import StatusChip from '../../../components/statusChip';
import OfficialAvatar from '../../../components/officialAvatar';
import { employmentTypeOptions } from '../../../networkRequest/officialsService';

export const createOfficialsColumns = (
  agencyOfficialTypesMap: AgencyOfficialTypesMap,
  agencyOfficialRoles: AgencyOfficialRolesMap,
  theme: Theme
): GridColDef[] => {
  const OverflowMenuCell: React.FC<{ row: OfficialData }> = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton size="small" onClick={handleOpen}>
          <GridMoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuItem component={Link} to={`./${row.id}`} onClick={handleClose}>
            User Details
          </MenuItem>
        </Menu>
      </>
    );
  };

  return [
    {
      field: 'first_name',
      headerName: 'First Name',
      editable: true,
      renderCell: (params: { row: OfficialData }) => (
        <OfficialAvatar img_url={params.row.img_url} name={params.row.first_name} square={false} />
      ),
      width: 150
    },
    { field: 'last_name', headerName: 'Last Name', editable: true, width: 150 },
    { field: 'title', headerName: 'Title', editable: true, width: 225 },
    {
      field: 'agency_official_type_id',
      headerName: 'Type',
      editable: true,
      type: 'singleSelect',
      valueGetter: (params: number) => {
        return agencyOfficialTypesMap.get(params)?.name || 'None';
      },
      renderCell: (params: {
        row: { agency_official_type: { name: string; id: number } | null };
      }) => {
        return params.row.agency_official_type ? params.row.agency_official_type?.name : 'None';
      },
      valueOptions: ['None', ...Array.from(agencyOfficialTypesMap).map(([, info]) => info.name)],
      width: 225
    },
    {
      field: 'employment_type',
      headerName: 'Employment Type',
      editable: true,
      type: 'singleSelect',
      valueOptions: employmentTypeOptions,
      width: 150
    },
    {
      field: 'role_name',
      headerName: 'Role',
      editable: true,
      type: 'singleSelect',
      valueOptions: Array.from(agencyOfficialRoles).map(([name]) => name),
      width: 150
    },
    {
      field: 'invite_status',
      headerName: 'Invite Status',
      renderCell: (params: { row: { invite_status: string } }) => (
        <StatusChip status={params.row.invite_status} theme={theme} />
      ),
      width: 150
    },
    {
      field: 'status',
      headerName: 'Job Status',
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: 'active', label: <StatusChip status="active" theme={theme} /> },
        { value: 'deactivated', label: <StatusChip status="inactive" theme={theme} /> },
        { value: 'suspended', label: <StatusChip status="suspended" theme={theme} /> }
      ],
      renderCell: (params: { row: OfficialData }) => (
        <StatusChip status={params.row.status} theme={theme} />
      ),
      width: 150
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      width: 225
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params: { row: OfficialData }) => <OverflowMenuCell row={params.row} />
    }
  ];
};
